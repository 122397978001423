import { createContext, useEffect, useReducer, useContext } from "react";
import AuthReducer from "./AuthReducer";
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase'
import { AuthContext } from "./AuthContext";

/**
 * Contexto de cierre de sesión.
 */
export const LogoutContext = createContext(null);

/**
 * Proveedor de cierre de sesión.
 *
 * @param {Object} props - Propiedades del proveedor de cierre de sesión.
 * @returns {JSX.Element} Elemento JSX que envuelve a los componentes hijos.
 */
export const LogoutProvider = ({ children }) => {
  const { dispatch } = useContext(AuthContext);
  const navigate = useNavigate();

  /**
 * Maneja el evento de cierre de sesión.
 */
  const handleLogout = () => {
    auth.signOut()
      .then(() => {
        dispatch({ type: 'LOGOUT' });
        navigate('/login');
      })
      .catch((error) => {
        console.log("logout failed")
      });
  }

  return (
    <LogoutContext.Provider value={handleLogout}>
      {children}
    </LogoutContext.Provider>
  );
};

