import React, { useState } from 'react';
import NotificationView from './NotificationView';
import './Notifications.scss'; 
import NotificationsCombined from './NotificationsCombined';

const Notifications = () => {
  const [showNotificationView, setShowNotificationView] = useState(false);
  const [buttonText, setButtonText] = useState('Ver Notificaciones');
  const [labelText, setLabelText] = useState('Notificación');


  const handleToggleNotificationView = () => {
    setShowNotificationView(!showNotificationView);

    if (showNotificationView) {
      setButtonText('Ver Notificaciones');
      setLabelText('Notificación');
    } else {
      setButtonText('Enviar Notificación');
      setLabelText('Últimas notificaciones');
    }
  };

  const handleGoBack = () => {
    setShowNotificationView(false);
    setButtonText('Ver Notificaciones');
    setLabelText('Notificación');
  };

  return (
    <div className="notification-form-container">
      <h2 className={`label-title ${showNotificationView && 'showNotificationView-text'}`}>{labelText}</h2>

      <div className='button-container'>
        <div className={showNotificationView ? "notification-view-button-container" : "view-notifications-button-container"}>
          {showNotificationView ? (
            <button className="notification-view-button" onClick={handleGoBack}>
              {buttonText}
            </button>
          ) : (
            <button className="view-notifications-button" onClick={handleToggleNotificationView}>
              {buttonText}
            </button>
          )}
        </div>
      </div>

      {showNotificationView ? (
        <NotificationView onGoBack={handleGoBack} />
      ) : (
        <NotificationsCombined />)}
    </div>
  );
};

export default Notifications;