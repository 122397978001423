import "./update.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useEffect, useState, useContext } from "react";
import {
  doc,
  serverTimestamp,
  setDoc,
  getDocs,
  getDoc,
  where,
  collection,
  query,
} from "firebase/firestore";
import { db, storage } from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { useSidebarState } from "../../components/sidebar/SidebarStateContext";

/**
 * Componente para la actualización de una cliente.
 *
 * @param {Object} props - Propiedades del componente.
 * @param {Array} props.inputs - Datos de entrada para el formulario.
 * @param {string} props.title - Título del componente.
 * @returns {JSX.Element} - Componente Updatecliente.
 */
const Updatecliente = ({ inputs, title }) => {
  const [file, setFile] = useState("");
  const [data, setData] = useState({});
  const [per, setPerc] = useState(null);

  const navigate = useNavigate();

  const { clienteId } = useParams();
  const { currentUser } = useContext(AuthContext);
  const { collapsed } = useSidebarState();

  // Agregar una clase basada en el estado de colapso
  const containerClass = collapsed ? "newContainer collapsed" : "newContainer";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const serviciosSnapshot = await getDocs(
          query(
            collection(db, "servicios"),
            where("admins", "array-contains", currentUser.email)
          )
        );

        if (!serviciosSnapshot.empty) {
          const servicioDoc = serviciosSnapshot.docs[0];

          const clientesSnapshot = await getDocs(
            collection(servicioDoc.ref, "clientes")
          );

          const clientes = clientesSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          const clienteData = clientes.find((cliente) => cliente.id === clienteId);

          if (clienteData) {
            setData(clienteData);
          }
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, [currentUser, clienteId]);


  useEffect(() => {
    const uploadFile = () => {
      const name = new Date().getTime() + file.name;

      const storageRef = ref(storage, file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          setPerc(progress);
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setData((prev) => ({ ...prev, img: downloadURL }));
          });
        }
      );
    };
    file && uploadFile();
  }, [file]);

  /**
 * Maneja el cambio de los campos de entrada del formulario.
 *
 * @param {Object} e - Evento de cambio.
 */
  const handleInput = (e) => {
    const id = e.target.id;
    const value = e.target.value;

    setData({ ...data, [id]: value });
  };

  /**
 * Maneja la actualización de la cliente.
 *
 * @param {Object} e - Evento de envío del formulario.
 */
  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const serviciosSnapshot = await getDocs(
        query(
          collection(db, "servicios"),
          where("admins", "array-contains", currentUser.email)
        )
      );

      if (!serviciosSnapshot.empty) {
        const servicioDoc = serviciosSnapshot.docs[0];

        const docRef = doc(
          servicioDoc.ref,
          "clientes",
          clienteId
        );

        await setDoc(docRef, {
          ...data,
          timeStamp: serverTimestamp(),
        });

        navigate(-1);
      }
    } catch (err) {
      console.log(err);
    }
  };


  return (
    <div className="new">
      <Sidebar />
      <div className={containerClass}>
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          <div className="left">
            <img
              src={
                file
                  ? URL.createObjectURL(file)
                  : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
              }
              alt=""
            />
          </div>
          <div className="right">
            <form onSubmit={handleUpdate}>
              <div className="formInput">
                <label htmlFor="file">
                  Image: <DriveFolderUploadOutlinedIcon className="icon" />
                </label>
                <input
                  type="file"
                  id="file"
                  onChange={(e) => setFile(e.target.files[0])}
                  style={{ display: "none" }}
                />
              </div>
              {inputs.map((input) => (
                <div className="formInput" key={input.id}>
                  <label>{input.label}</label>
                  <input
                    id={input.id}
                    type={input.type}
                    placeholder={input.placeholder}
                    onChange={handleInput}
                    value={data[input.id] || ""}
                  />
                </div>
              ))}
              <button disabled={per !== null && per < 100} type="submit">
                Actualizar
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );

}
export default Updatecliente;

