import React, { useEffect, useState, useContext } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import { db } from '../../firebase';
import { collection, getDocs, doc } from 'firebase/firestore';
import { format } from 'date-fns';
import './chart.scss';
import { AuthContext } from '../../context/AuthContext';

const VigilanteBarChart = ({ vigilante }) => {
  const [dailyCounts, setDailyCounts] = useState({
    Monday: { ims: 0, intervencion: 0, denuncia: 0 },
    Tuesday: { ims: 0, intervencion: 0, denuncia: 0 },
    Wednesday: { ims: 0, intervencion: 0, denuncia: 0 },
    Thursday: { ims: 0, intervencion: 0, denuncia: 0 },
    Friday: { ims: 0, intervencion: 0, denuncia: 0 },
    Saturday: { ims: 0, intervencion: 0, denuncia: 0 },
    Sunday: { ims: 0, intervencion: 0, denuncia: 0 }
  });

  const [chartWidth, setChartWidth] = useState(1000);
  const { currentService } = useContext(AuthContext);

  useEffect(() => {
    if (vigilante && currentService) {
      const serviceDocRef = doc(db, 'servicios', currentService.id);
      const documentsRef = collection(serviceDocRef, 'documents');

      getDocs(documentsRef).then((documentsSnapshot) => {
        const updatedCounts = { ...dailyCounts };

        documentsSnapshot.forEach((document) => {
          const documentData = document.data();

          if (documentData.vigilante && documentData.vigilante.replace('@gmail.com', '') === vigilante) {
            const vigilanteDate = documentData.fecha.toDate();
            const dayOfWeek = format(vigilanteDate, 'EEEE');

            updatedCounts[dayOfWeek][documentData.documentTypeId]++;
          }
        });

        setDailyCounts(updatedCounts);
      }).catch(error => {
        console.error("Error obteniendo documentos: ", error);
      });
    }
  }, [vigilante, currentService]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1100) {
        setChartWidth(1200 * 0.25);
      } else if (window.innerWidth < 1450) {
        setChartWidth(1200 * 0.7);
      } else {
        setChartWidth(1200);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Calcula los totales de documentos para cada tipo
  const totalIms = Object.values(dailyCounts).reduce((acc, curr) => acc + curr.ims, 0);
  const totalIntervencion = Object.values(dailyCounts).reduce((acc, curr) => acc + curr.intervencion, 0);
  const totalDenuncia = Object.values(dailyCounts).reduce((acc, curr) => acc + curr.denuncia, 0);

  const renderLegend = (props) => {
    const { payload } = props;
    return (
      <div className="custom-legend">
        {payload.map((entry, index) => (
          <div key={`item-${index}`} className="custom-legend-item">
            <div className="custom-legend-item-title" style={{ color: entry.color }}>
              {entry.value} {entry.payload[entry.dataKey]}
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="chart-wrapper">
      <h6 className="chart-title">DATOS SEMANALES</h6>
      <div className="chart-container">
        <BarChart width={700} height={370} data={Object.keys(dailyCounts).map(day => ({ day, ...dailyCounts[day] }))}>
          <XAxis dataKey="day" />
          <YAxis />
          <Tooltip />
          <Legend content={renderLegend} payload={[
            { value: 'denuncia', color: "rgba(70, 120, 195, 1)", dataKey: 'denuncia', payload: { denuncia: totalDenuncia } },
            { value: 'intervencion', color: "rgba(26, 41, 66, 1)", dataKey: 'intervencion', payload: { intervencion: totalIntervencion } },
            { value: 'ims', color: "rgba(122, 164, 192, 1)", dataKey: 'ims', payload: { ims: totalIms } },
          ]} />
          <Bar dataKey="denuncia" fill="rgba(70, 120, 195, 1)" barSize={35} isAnimationActive={false} />
          <Bar dataKey="intervencion" fill="rgba(26, 41, 66, 1)" barSize={35} isAnimationActive={false} />
          <Bar dataKey="ims" fill="rgba(122, 164, 192, 1)" barSize={35} isAnimationActive={false} />
        </BarChart>
      </div>
    </div>
  );
};

export default VigilanteBarChart;