import "./table.scss";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { db } from '../../firebase';
import { collection, doc, setDoc, addDoc, updateDoc, deleteDoc, getDoc, getDocs, where, query, orderBy, limit, select } from "firebase/firestore";
import JSZip from 'jszip';
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import {
    getFolderName, downloadSelectedDocumentsAsPDF, capitalizeFirstLetter,
    downloadSelectedPhotos, formatDateForFolder, downloadSelectedCSV,
    isWithinLastNDays, formatDate, applyDateFilter
} from './documentUtils';
import { uploadLogToFirestore } from '../../utils/logUtils.js'
import Bowser from "bowser";
import { ActionButtons, Table, FilterBar, DateFilterDropdown } from './ReportList.js'

/**
 * Componente List.
 * @returns {JSX.Element} - Elemento de React que representa la lista de documentos.
 */
const List = () => {
    const browser = Bowser.getParser(window.navigator.userAgent);
    const isSafari = browser.getBrowserName() === "Safari";

    const [rows, setRows] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedRows, setSelectedRows] = useState([]);
    const [allRowsSelected, setAllRowsSelected] = useState(false);
    const [filterDate, setFilterDate] = useState("");
    const [filterDateRegistro, setFilterDateRegistro] = useState("");
    const [filterVigilante, setFilterVigilante] = useState("");
    const [documentsLoaded, setDocumentsLoaded] = useState(true);
    const [searchTienda, setSearchTienda] = useState("");
    const [nombresLoaded, setNombresLoaded] = useState(false);
    const [deletedRows, setDeletedRows] = useState([]);
    const [dateFilter, setDateFilter] = useState("all"); // "all" or "last3Days"


    const { tipVS } = useParams();
    const { currentUser } = useContext(AuthContext);

    /**
 * Recupera los documentos del servicio actual.
 */
    useEffect(() => {
        const fetchData = async () => {
            // Verificar si se ha cargado correctamente antes de marcar como cargado
            if (!rows.some(row => row.nombre)) {
                setNombresLoaded(false);
            }
            if (rows.length > 0 && !nombresLoaded) {
                await populateNombre();
            }
        };

        fetchData();
    }, [rows, nombresLoaded]);


    useEffect(() => {
        fetchDocuments().then(() => {
            // Marcar que los documentos se han cargado
            setDocumentsLoaded(true);
        });
    }, [tipVS]);

    // Restaurar los valores desde localStorage al cargar la pestaña
    useEffect(() => {
        const persistedValues = JSON.parse(localStorage.getItem('filterValues'));

        if (persistedValues) {
            setSearchTerm(persistedValues.searchTerm);
            setFilterDate(persistedValues.filterDate);
            setFilterDateRegistro(persistedValues.filterDateRegistro);
            setFilterVigilante(persistedValues.filterVigilante);
            setSearchTienda(persistedValues.searchTienda);
        }
    }, []);

    // Guardar los valores en localStorage antes de cambiar de pestaña
    useEffect(() => {
        const valuesToPersist = {
            searchTerm,
            filterDate,
            filterDateRegistro,
            filterVigilante,
            searchTienda
        };
        localStorage.setItem('filterValues', JSON.stringify(valuesToPersist));
    }, [filterDate, filterDateRegistro, filterVigilante, searchTienda, searchTerm]);

    const fetchDocuments = async () => {
        try {
            const serviciosRef = collection(db, 'servicios');
            const q = query(serviciosRef, where('admins', 'array-contains', currentUser.email));
            const querySnapshot = await getDocs(q);
            if (!querySnapshot.empty) {
                const servicioDoc = querySnapshot.docs[0];
                const servicioData = servicioDoc.data();
                const servicioId = servicioDoc.id;

                const documentsRef = collection(serviciosRef, servicioId, 'documents');
                const documentsSnapshot = await getDocs(documentsRef);

                const documents = documentsSnapshot.docs.map((doc) => {
                    const documentData = doc.data();
                    // Acceder a los campos 'seconds' y 'nanoseconds' del objeto 'fecha'
                    const timestamp = documentData.fecha;
                    if (timestamp) {
                        const seconds = timestamp.seconds;
                        const nanoseconds = timestamp.nanoseconds;
                        // Construir la fecha a partir de los campos 'seconds' y 'nanoseconds'
                        const date = new Date(seconds * 1000 + nanoseconds / 1000000);
                        const dia = date.getDate();
                        const mes = date.getMonth() + 1;
                        const año = date.getFullYear();
                        const fechaFormateada = `${dia}/${mes}/${año}`;
                        documentData.fecha = fechaFormateada;
                    }
                    return { id: doc.id, ...documentData };
                }).filter((document) => !document.borrado); // Filtrar documentos borrados

                setRows(documents);

                return servicioId;
            }
        } catch (error) {
            console.error('Error al obtener los documentos:', error);
            uploadLogToFirestore('Error al obtener los documentos: ' + error);
        }
    };

    /**
      * Recupera y agrega el nombre del vigilante a cada documento.
      */
    const populateNombre = async () => {
        if (nombresLoaded) return;

        try {
            const serviciosRef = collection(db, 'servicios');
            const q = query(serviciosRef, where('admins', 'array-contains', currentUser.email));
            const querySnapshot = await getDocs(q);
            if (!querySnapshot.empty) {
                const servicioDoc = querySnapshot.docs[0];
                const servicioData = servicioDoc.data();
                const servicioId = servicioDoc.id;

                const documentsWithNombre = await Promise.all(
                    rows.map(async (document) => {
                        try {
                            if (!document.vigilante) {
                                console.log('Missing vigilante property in document:', document);
                                return document;
                            }

                            const vigilanteId = document.vigilante.replace('@gmail.com', '');

                            const vigilantesQuerySnapshot = await getDocs(query(collection(db, 'servicios', servicioId, 'vigilantes'), where('tipVS', '==', vigilanteId)));

                            if (!vigilantesQuerySnapshot.empty) {
                                const vigilanteData = vigilantesQuerySnapshot.docs[0].data();

                                const nombre = vigilanteData.nombre;
                                const apellidos = vigilanteData.apellidos;

                                const nombreCompleto = `${nombre} ${apellidos}`;
                                return { ...document, nombre: nombreCompleto };
                            } else {
                                return document;
                            }
                        } catch (error) {
                            console.error('Error en promesa individual:', error);
                            return document;
                        }
                    })
                );

                setRows(documentsWithNombre);
                setNombresLoaded(true);
            }
        } catch (error) {
            console.error('Error al obtener los documentos:', error);
        }
    };





    /**
 * Maneja el cambio en el campo de búsqueda.
 * @param {React.ChangeEvent<HTMLInputElement>} event - Evento de cambio del campo de búsqueda.
 */
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    /**
 * Maneja el cambio en la selección de todas las filas.
 * @param {React.ChangeEvent<HTMLInputElement>} event - Evento de cambio de la selección de todas las filas.
 */
    const handleSelectAllRows = (event) => {
        if (event.target.checked) {
            // Marcar todas las filas como seleccionadas
            const allRowsIds = filteredRows.map((row) => row.id);
            setSelectedRows(allRowsIds);
            setAllRowsSelected(true);
        } else {
            // Desmarcar todas las filas
            setSelectedRows([]);
            setAllRowsSelected(false);
        }
    };

    /**
 * Maneja el cambio en el campo de filtro de fecha de subida.
 * @param {React.ChangeEvent<HTMLInputElement>} event - Evento de cambio del campo de filtro de fecha de subida.
 */
    const handleFilterChange = (event) => {
        setFilterDate(event.target.value);
    };

    /**
 * Maneja el cambio en el campo de filtro de fecha de suceso.
 * @param {React.ChangeEvent<HTMLInputElement>} event - Evento de cambio del campo de filtro de fecha de suceso.
 */
    const handleFilterChangeDate = (event) => {
        setFilterDateRegistro(event.target.value);
    };

    /**
 * Maneja el cambio en el campo de filtro de vigilante.
 * @param {React.ChangeEvent<HTMLInputElement>} event - Evento de cambio del campo de filtro de vigilante.
 */
    const handleVigilanteFilterChange = (event) => {
        setFilterVigilante(event.target.value);
    };

    /**
 * Maneja el cambio en el campo de filtro de tienda.
 * @param {React.ChangeEvent<HTMLInputElement>} event - Evento de cambio del campo de filtro de tienda.
 */
    const handleTiendaFilterChange = (event) => {
        setSearchTienda(event.target.value);
    };

    /**
 * Maneja la selección/deselección de una fila.
 * @param {React.ChangeEvent<HTMLInputElement>} event - Evento de cambio de selección de la fila.
 * @param {string} id - ID de la fila seleccionada/deseleccionada.
 */
    const handleRowSelection = (event, id) => {
        if (event.target.checked) {
            setSelectedRows([...selectedRows, id]);
        } else {
            setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
        }

        // Verificar si la fila se ha marcado como borrada
        const isDeleted = event.target.checked && deletedRows.includes(id);

        // Si la fila está marcada como borrada, eliminarla de la lista de borrados
        if (isDeleted) {
            setDeletedRows(deletedRows.filter((rowId) => rowId !== id));
        }
    };


    /**
 * Maneja la descarga de los documentos seleccionados.
 */
    async function handleDownloadSelected() {
        // Descargar los documentos seleccionados en un archivo ZIP
        const selectedDocuments = rows.filter(
            (row) =>
                selectedRows.includes(row.id) &&
                (row.documentTypeId === "legal" ||
                    row.documentTypeId === "ims" ||
                    row.documentTypeId === "112" ||
                    row.documentTypeId === "denuncia" ||
                    row.documentTypeId === "intervencion" ||
                    row.documentTypeId === "parte de trabajo")
        );

        const zip = new JSZip();
        const folder = zip.folder('documentos');

        await downloadSelectedPhotos(
            selectedDocuments.filter(
                (document) => document.documentTypeId === "legal" || document.documentTypeId === "ims"
                    || document.documentTypeId === "denuncia" || document.documentTypeId === "intervencion"

            ),
            folder
        );

        downloadSelectedCSV(selectedDocuments, folder);

        await downloadSelectedDocumentsAsPDF(selectedDocuments, folder);

        // Agregar el campo 'downloaded: true' en la base de datos
        const currentTimeStamp = new Date();
        const updatedDocuments = selectedDocuments.map((document) => ({
            ...document,
            downloaded: true,
            downloadedTime: currentTimeStamp,
        }));

        // Actualizar las filas en el estado
        setRows([...rows, ...updatedDocuments]);

        // Agregar el campo 'downloaded: true' en la base de datos
        const servicioId = await fetchDocuments();
        const serviciosRef = collection(db, 'servicios');
        for (const document of updatedDocuments) {
            await updateDoc(doc(serviciosRef, servicioId, 'documents', document.id), {
                downloaded: true,
                downloadedTime: currentTimeStamp,
            });
        }

        zip.generateAsync({ type: "blob" }).then((content) => {
            // Descargar archivo ZIP
            const link = document.createElement("a");
            link.href = URL.createObjectURL(content);
            link.download = "documentos.zip";
            link.click();

            uploadLogToFirestore('documentos descargados: ' + link.href);
            // Limpiar la selección después de la descarga
            setSelectedRows([]);
        });
    }

    const handleDeleteSelected = async () => {
        const currentTimeStamp = new Date();
        // Establecer el campo "borrado" en true para las filas seleccionadas
        const updatedDocuments = rows.map((row) => {
            if (selectedRows.includes(row.id)) {
                return {
                    ...row,
                    borrado: true,
                    borradoTime: currentTimeStamp,
                };
            }
            return row;
        });

        // Actualizar las filas en el estado
        setRows(updatedDocuments);

        // Actualizar la lista de filas borradas
        setDeletedRows([...deletedRows, ...selectedRows]);

        // Desmarcar todas las filas seleccionadas
        setSelectedRows([]);

        // Obtener el servicioId
        const servicioId = await fetchDocuments();

        // Actualizar los documentos en la base de datos
        const serviciosRef = collection(db, 'servicios');
        for (const document of updatedDocuments) {
            if (selectedRows.includes(document.id)) {
                await updateDoc(doc(serviciosRef, servicioId, 'documents', document.id), {
                    borrado: true,
                    borradoTime: currentTimeStamp,
                });
            }
        }
    };


    /**
 * Filtra las filas de documentos según los términos de búsqueda y los filtros seleccionados.
 * @returns {Array} - Filas de documentos filtrados.
 */
    const filteredRows = rows.filter((row) => {
        const fecha = row.fields?.FECHA ?? '';
        const nombre = row.nombre ?? '';
        const documentDate = formatDate(row.fecha);

        const isMatchingSearchTerm = row.documentTypeId?.toLowerCase().includes(searchTerm.toLowerCase()) ?? false;
        const isMatchingFecha = row.fecha?.toLowerCase().includes(filterDate.toLowerCase()) ?? false;
        const isMatchingFechaRegistro = fecha.toLowerCase().includes(filterDateRegistro.toLowerCase()) ?? false;
        const isMatchingTienda = !row.tienda || row.tienda?.toLowerCase().includes(searchTienda.toLowerCase());
        const isMatchingVigilante = nombre.toLowerCase().includes(filterVigilante.toLowerCase());
        const isNotDeleted = !deletedRows.includes(row.id);

        return (
            isMatchingSearchTerm &&
            isMatchingFecha &&
            isMatchingFechaRegistro &&
            isMatchingTienda &&
            isMatchingVigilante &&
            isNotDeleted &&
            applyDateFilter(row, documentDate, dateFilter)
        );
    });


    return (
        <div className="tableContainer">
            <h2 className="datatableTitle">Reportes</h2>
            <FilterBar
                searchTerm={searchTerm}
                handleSearchChange={handleSearchChange}
                filterDate={filterDate}
                handleFilterChange={handleFilterChange}
                filterDateRegistro={filterDateRegistro}
                handleFilterChangeDate={handleFilterChangeDate}
                searchTienda={searchTienda}
                handleTiendaFilterChange={handleTiendaFilterChange}
                filterVigilante={filterVigilante}
                handleVigilanteFilterChange={handleVigilanteFilterChange}
            />
            <div className="tableControlsRow">
                <ActionButtons
                    handleDownloadSelected={handleDownloadSelected}
                    selectedRows={selectedRows}
                    handleDeleteSelected={handleDeleteSelected}
                />
                <DateFilterDropdown dateFilter={dateFilter} setDateFilter={setDateFilter} />
            </div>
            <Table
                filteredRows={filteredRows}
                allRowsSelected={allRowsSelected}
                selectedRows={selectedRows}
                handleSelectAllRows={handleSelectAllRows}
                handleRowSelection={handleRowSelection}
                handleDeleteSelected={handleDeleteSelected}
            />
        </div>
    );
};

export default List;