import React from 'react';
import './privacyPolicy.scss'


const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h2>Política de Privacidad de Sentry</h2>
      <p>Fecha de última actualización: 20 de septiembre de 2023</p>

      <h3>Introducción</h3>
      <p>Gracias por elegir Sentry. Nos comprometemos a proteger y respetar tu privacidad. Esta política describe cómo tratamos la información personal que nos proporcionas al usar nuestra aplicación.</p>

      <h3>Datos que Recopilamos</h3>
      <p>Sentry recopila los nombres de los usuarios con el único propósito de administrar las operaciones internas de BePart Innova. Esta información es esencial para ofrecerte un servicio adecuado y personalizado.</p>

      <h3>Uso de Datos</h3>
      <p>Nos tomamos muy en serio la privacidad de tus datos. Aunque recopilamos nombres de usuarios para la administración interna de BePart Innova, te garantizamos que no compartimos, vendemos ni alquilamos estos datos a terceros.</p>

      <h3>Seguridad</h3>
      <p>Nos esforzamos por proteger la seguridad de los datos de nuestros usuarios. Adoptamos medidas adecuadas para proteger la información contra el acceso no autorizado, la divulgación, la alteración o la destrucción.</p>

      <h3>Cambios a esta Política de Privacidad</h3>
      <p>Podemos actualizar nuestra Política de Privacidad ocasionalmente. Cualquier cambio que hagamos a nuestra política de privacidad en el futuro se publicará en esta página y, cuando corresponda, te notificaremos por correo electrónico o mediante un aviso en nuestra aplicación.</p>

      <h3>Contacto</h3>
      <p>Para cualquier pregunta sobre esta política de privacidad o nuestras prácticas de privacidad, puedes contactarnos en alberto.rubiales@betpart-innova.com.</p>
    </div>
  );
};

export default PrivacyPolicy;
