import React, { useEffect, useState } from "react";
import { collection, getDocs, getDoc, doc, query, where, deleteDoc } from "firebase/firestore";
import { useParams, Link } from "react-router-dom";
import { db } from "../../firebase";
import { DataGrid } from "@mui/x-data-grid";
import { tiendasColumns } from "../../datatablesource";
import customLocaleText from './LocaleText.js'
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';

/**
 * Componente para mostrar una tabla de tiendas de un cliente.
 * Permite filtrar y realizar acciones como eliminar y actualizar tiendas.
 *
 * @returns {JSX.Element} - Componente DatatableTiendasCliente.
 */
const DatatableTiendasCliente = () => {
  const { clienteId, nombreCliente } = useParams(); // Obtiene el clienteId de la URL

  const [tiendas, setTiendas] = useState([]);
  const [filter, setFilter] = useState("");
  const [clienteImg, setClienteImg] = useState(""); // Agregamos estado para la imagen del cliente

  /**
  * Función para obtener y cargar los datos de las tiendas del cliente y la imagen del cliente.
  */
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Consulta la colección de servicios
        const serviciosQuery = collection(db, "servicios");
        const serviciosSnapshot = await getDocs(serviciosQuery);

        const tiendasData = [];

        for (const servicioDoc of serviciosSnapshot.docs) {
          // Consulta las tiendas y la información del cliente dentro de cada servicio
          const tiendasQuery = query(collection(servicioDoc.ref, "tiendas"), where("cliente", "==", clienteId));
          const tiendasSnapshot = await getDocs(tiendasQuery);

          tiendasSnapshot.forEach((tiendaDoc) => {
            const tiendaData = tiendaDoc.data();
            if (!tiendaData.borrado) {
              tiendasData.push({ id: tiendaDoc.id, ...tiendaData });
            }
          });

          // Consulta el documento del cliente para obtener la imagen
          const clienteDoc = doc(servicioDoc.ref, "clientes", clienteId);
          const clienteSnap = await getDoc(clienteDoc);
          if (clienteSnap.exists()) {
            const clienteData = clienteSnap.data();
            setClienteImg(clienteData.img || ""); // Establecer la imagen del cliente si está disponible
          }
        }

        setTiendas(tiendasData);
      } catch (error) {
        console.error("Error al obtener las tiendas y la imagen del cliente:", error);
      }
    };

    fetchData();
  }, [clienteId]);


  /**
 * Función para manejar la eliminación de una tienda.
 *
 * @param {string} id - ID de la tienda a eliminar.
 */
  const handleDelete = async (id) => {
    try {
      const confirmDelete = window.confirm("¿Estás seguro de que deseas eliminar esta tienda?");
      if (confirmDelete) {
        const serviciosSnapshot = await getDocs(collection(db, "servicios"));

        for (const servicioDoc of serviciosSnapshot.docs) {
          const tiendaRef = doc(servicioDoc.ref, "tiendas", id);
          await deleteDoc(tiendaRef, { borrado: true });
        }

        setTiendas(tiendas.filter((item) => item.id !== id));
      }
    } catch (err) {
      console.log(err);
    }
  };



  const actionColumn = [
    {
      field: "action",
      headerName: "Acción",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link to={`/tiendas/update/${encodeURIComponent(params.row.id)}`} style={{ textDecoration: "none" }}>
              <div className="actualizarButton">Actualizar</div>
            </Link>
            <DeleteIcon className="deleteIcon" onClick={() => handleDelete(params.row.id)} />
          </div>
        );
      },
    },
  ];


  const filteredData = tiendas.filter((row) => {
    const rowValues = Object.values(row).join("").toLowerCase();
    return rowValues.includes(filter.toLowerCase());
  });

  return (
    <div className="datatable">
      <div className="datatableTitle datatableTitle-tiendas">
        <div className="headTiendas">
          <img src={clienteImg} alt={`Imagen de ${nombreCliente}`} className="clienteImg" />
          <h2 className="title-registro-tiendas">
            Servicios del {nombreCliente}
          </h2>
        </div>
        <div className="rightActions">
          <div className="searchContainer">
            <input
              type="text"
              placeholder="Buscar"
              className="searchInput"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
            <SearchIcon className="searchIcon" /> {/* Ícono de lupa usando MUI */}

          </div>
          <Link
            to={`/clientes/${encodeURIComponent(clienteId)}/newClienteTienda/${encodeURIComponent(nombreCliente)}`}
            className="link link-right"
          >
            Nuevo servicio
          </Link>
        </div>
      </div>
      <DataGrid
        className="datagrid"
        rows={filteredData}
        columns={tiendasColumns.concat(actionColumn)}
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection
        localeText={customLocaleText}
      />
    </div>
  );
};

export default DatatableTiendasCliente;