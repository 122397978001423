import "./new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useEffect, useState, useContext } from "react";
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  getDocs,
  where,
  query,
} from "firebase/firestore";
import { auth, db, storage } from "../../firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { uploadLogToFirestore } from "../../utils/logUtils";
import noImage from '../../images/no-image.png'
import { useSidebarState } from "../../components/sidebar/SidebarStateContext";

/**
 * Componente para crear una nueva cliente.
 *
 * @param {Object} inputs - Los campos de entrada para la cliente.
 * @param {string} title - El título del formulario.
 * @returns {JSX.Element} - Componente Newcliente.
 */
const NewCliente = ({ inputs, title }) => {
  const [file, setFile] = useState("");
  const [data, setData] = useState({});
  const [per, setPerc] = useState(null);

  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const { collapsed } = useSidebarState();
  const containerClass = collapsed ? "newContainer collapsed" : "newContainer";

  useEffect(() => {
    const uploadFile = () => {
      const name = new Date().getTime() + file.name;

      const storageRef = ref(storage, file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          setPerc(progress);
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setData((prev) => ({ ...prev, img: downloadURL }));
          });
        }
      );
    };
    file && uploadFile();
  }, [file]);

  /**
 * Maneja el cambio de los campos de entrada.
 *
 * @param {Object} e - Evento del campo de entrada.
 */
  const handleInput = (e) => {
    const id = e.target.id;
    const value = e.target.value;

    setData({ ...data, [id]: value });
  };

  /**
 * Maneja el registro de la nueva cliente.
 *
 * @param {Object} e - Evento del formulario.
 */
  const handleAdd = async (e) => {
    e.preventDefault();
    console.log("data", data);

    try {
      const serviciosRef = collection(db, "servicios");
      const q = query(
        serviciosRef,
        where("admins", "array-contains", currentUser.email)
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const servicioDoc = querySnapshot.docs[0];
        const servicioId = servicioDoc.id;
        const clientesRef = collection(servicioDoc.ref, "clientes");

        await addDoc(clientesRef, {
          ...data,
          timeStamp: serverTimestamp(),
        });

        uploadLogToFirestore('cliente añadido');
        navigate(-1);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="new">
      <Sidebar />
      <div className={containerClass}>
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          <div className="left">
            <img
              src={
                file
                  ? URL.createObjectURL(file)
                  : noImage
              }
              alt=""
            />
            <div className='upload-label'>
              <label htmlFor="file">
                <DriveFolderUploadOutlinedIcon className="icon" /> Subir imagen
              </label>
            </div>
          </div>
          <div className="right">
            <form onSubmit={handleAdd}>
              <div className="formInput">
                <input
                  type="file"
                  id="file"
                  onChange={(e) => setFile(e.target.files[0])}
                  style={{ display: "none" }}
                />
              </div>

              {inputs.map((input) => (
                <div className="formInput" key={input.id}>
                  <label>{input.label}</label>
                  <input
                    id={input.id}
                    type={input.type}
                    placeholder={input.placeholder}
                    onChange={handleInput}
                  />
                </div>
              ))}
              <div className="button-container">
                <button disabled={per !== null && per < 100} type="submit">
                  Registrar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewCliente;
