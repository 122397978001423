import "./list.scss"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import Notifications from "../../components/notifications/Notifications"
import { useSidebarState } from "../../components/sidebar/SidebarStateContext";

/**
 * Componente de la página de lista.
 *
 * @returns {JSX.Element} Elemento JSX que representa la página de lista.
 */
const NotificationsForm = () => {
  const { collapsed } = useSidebarState();

  // Agregar una clase basada en el estado de colapso
  const containerClass = collapsed ? "listContainer collapsed" : "listContainer";

  return (
    <div data-testid="sidebar2" className="list">
      <Sidebar />
      <div data-testid="navbar2" className={containerClass}>
        <Navbar />
        <Notifications />
      </div>
    </div>
  )
}

export default NotificationsForm