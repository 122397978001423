import React, { useState } from 'react';
import '../styles/iconPicker.scss';

// Lista de URLs de tus íconos
const icons = [ 
  'https://firebasestorage.googleapis.com/v0/b/appsecoex.appspot.com/o/logos%2Ficons8-bodyguard-male-50.png?alt=media&token=d3680f09-015c-4344-9f5d-384fb076498c&_gl=1*14rt8vl*_ga*MTEzNTg4NDIzNS4xNjc3ODA1Njc5*_ga_CW55HF8NVT*MTY5NjM1OTE3NS4zNDEuMS4xNjk2MzU5MzUyLjMyLjAuMA..',
  'https://firebasestorage.googleapis.com/v0/b/appsecoex.appspot.com/o/logos%2Ficons8-juez-de-la-corte-50.png?alt=media&token=5bc4afa2-5e02-4f8f-8b50-43722e5ba49c&_gl=1*18aofe7*_ga*MTEzNTg4NDIzNS4xNjc3ODA1Njc5*_ga_CW55HF8NVT*MTY5NjM1OTE3NS4zNDEuMS4xNjk2MzU5Mzc0LjEwLjAuMA..',
  'https://firebasestorage.googleapis.com/v0/b/appsecoex.appspot.com/o/logos%2Ficons8-mantenimiento-50.png?alt=media&token=c6c39423-191c-43ba-b37c-2395ccec3a34&_gl=1*1g1y2s2*_ga*MTEzNTg4NDIzNS4xNjc3ODA1Njc5*_ga_CW55HF8NVT*MTY5NjM1OTE3NS4zNDEuMS4xNjk2MzU5Mzg0LjYwLjAuMA..',
  'https://firebasestorage.googleapis.com/v0/b/appsecoex.appspot.com/o/logos%2Ficons8-palacio-de-justicia-50.png?alt=media&token=39ae7ebb-ab98-426a-8b40-e20a1dda6910&_gl=1*1mlv2b1*_ga*MTEzNTg4NDIzNS4xNjc3ODA1Njc5*_ga_CW55HF8NVT*MTY5NjM1OTE3NS4zNDEuMS4xNjk2MzU5MzkzLjUxLjAuMA..',
  'https://firebasestorage.googleapis.com/v0/b/appsecoex.appspot.com/o/logos%2Ficons8-trabajo-permanente-50.png?alt=media&token=2d33fa27-820d-49dc-899b-a27ffae5652d&_gl=1*smk233*_ga*MTEzNTg4NDIzNS4xNjc3ODA1Njc5*_ga_CW55HF8NVT*MTY5NjM1OTE3NS4zNDEuMS4xNjk2MzU5NDAyLjQyLjAuMA..',
  'https://firebasestorage.googleapis.com/v0/b/appsecoex.appspot.com/o/logos%2FSin%20t%C3%ADtulo.png?alt=media&token=6893203c-e0f1-4479-8019-9d12a6d04e8c&_gl=1*1tnjs07*_ga*MTEzNTg4NDIzNS4xNjc3ODA1Njc5*_ga_CW55HF8NVT*MTY5NjM1OTE3NS4zNDEuMS4xNjk2MzU5NDE4LjI2LjAuMA..'
];

/**
 * Componente de selección de iconos que muestra una lista de iconos y permite al usuario elegir uno.
 *
 * @component
 * @param {Object} props - Propiedades del componente.
 * @param {string} props.selectedIcon - URL del icono seleccionado actualmente.
 * @param {function} props.onSelect - Función de devolución de llamada que se ejecuta al seleccionar un nuevo icono.
 * @returns {JSX.Element} - React JSX que representa el componente IconPicker.
 */
const IconPicker = ({ selectedIcon, onSelect }) => {
  const [showIcons, setShowIcons] = useState(false); // Controla si se muestra la caja de íconos

  return (
    <div className="icon-picker">
      <div className="selected-icon" onClick={() => setShowIcons(!showIcons)}>
        {selectedIcon ? <img className="selected-icon-img" src={selectedIcon} alt="Selected Icon" /> : "Icono"}
      </div>
      {showIcons && (
        <div className="icon-grid">
          {icons.map((iconURL, index) => (
            <img
              key={index}
              src={iconURL}
              alt={`Icono ${index + 1}`}
              onClick={() => {
                onSelect(iconURL);
                setShowIcons(false); // Cierra la caja después de seleccionar un ícono
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default IconPicker;
