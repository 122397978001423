import './style/styles.css'; // Asegúrate de importar tu archivo CSS


export const userInputs = [
  {
    id: "dni",
    label: "DNI",
    type: "text",
    placeholder: "DNI",
  },
  {
    id: "tipVS",
    label: "Tip VS",
    type: "text",
    placeholder: "Número identificación del vigilante",
  },
];


export const productInputs = [
  {
    id: "numTienda",
    label: "Identificador servicio",
    type: "text",
    placeholder: "tienda",
  },
  {
    id: "localizacion",
    label: "Localización",
    type: "text",
    placeholder: "localización",
  },
];

export const clientesInputs = [
  {
    id: "nombre",
    label: "Identificador cliente",
    type: "text",
    placeholder: "nombre",
  },
];
