import './style/styles.css'

export const userColumns = [
  {
    field: "id", headerName: "ID",
    width: 200,
    hide: true // Oculta la columna en la vista,
  }, 
  {
    field: "nombre",
    headerName: "Nombre",
    width: 200,
  },
  {
    field: "apellidos",
    headerName: "Apellidos",
    width: 200,
  },
  {
    field: "tiendas",
    headerName: "Servicios",
    width: 200,
    renderCell: (params) => (
      <div className="custom-scrollbar" style={{
        display: 'flex',
        overflowX: 'auto',
        whiteSpace: 'nowrap',  // Esto evita que los elementos se rompan en varias líneas
      }}>
        {params.value?.map ? params.value.map((tienda, index) => (
          <div key={index} style={{
            borderRadius: '10px',
            background: 'rgba(73, 80, 87, 0.27)',
            padding: '5px 10px',
            color: 'black',
            marginRight: '5px',
            marginBottom: '5px'
          }}>
            {tienda}
          </div>
        )) : null}
      </div>
    ),
  },
  {
    field: "tipVS",
    headerName: "Tip VS",
    width: 150,
  },
  {
    field: "dni",
    headerName: "DNI",
    width: 150,
  },
];

export const tiendasColumns = [
  { field: "id", headerName: "ID", width: 200, hide: true },
  {
    field: "nombreCliente",
    headerName: "cliente",
    width: 200,
  },
  {
    field: "localizacion",
    headerName: "Localización",
    width: 300,
  },
  {
    field: "numTienda",
    headerName: "Identificador de Servicio",
    width: 300,
    renderCell: (params) => (
      <div style={{
        borderRadius: '10px',
        background: 'rgba(73, 80, 87, 0.27)',
        padding: '5px 10px',
        color: 'black',
        whiteSpace: 'nowrap'  // Esto evita que los elementos se rompan en varias líneas
      }}>
        {params.value}
      </div>
    ),
  },
];

export const clientesColumns = [
  { field: "id", headerName: "ID", width: 70 },
  {
    field: "nombre",
    headerName: "nombre",
    width: 150,
  },
];


