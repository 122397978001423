import { db } from '../../firebase';
import { collection, addDoc } from 'firebase/firestore';

const notificationsCollection = (servicioId) => collection(db, 'servicios', servicioId, 'notifications');

const addNotification = async (title, body, to, dateSent, servicioId) => {
  if (!servicioId) {
    console.error('No se ha encontrado un servicio asociado al usuario.');
    return;
  }

  const notificationData = {
    title,
    body,
    to,
    dateSent,
  };

  try {
    const docRef = await addDoc(notificationsCollection(servicioId), notificationData);
    console.log('Notificación registrada en Firestore con ID:', docRef.id);
  } catch (error) {
    console.error('Error al agregar la notificación a Firestore:', error);
  }
};

export { addNotification };
