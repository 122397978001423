import React, { useState, useEffect, useContext } from 'react';
import { sendNotification } from './NotificationHandler';
import { sendNotificationToStore } from './GroupNotifications';
import { AuthContext } from '../../context/AuthContext';
import { db } from '../../firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import './Notifications.scss';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

const NotificationsCombined = () => {
    const { currentService } = useContext(AuthContext);
    const servicioId = currentService ? currentService.id : null;


    const [notificationTitle, setNotificationTitle] = useState('');
    const [notificationBody, setNotificationBody] = useState('');
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState('');
    const [services, setServices] = useState([]);
    const [selectedService, setSelectedService] = useState('');
    const [selectedServices, setSelectedServices] = useState([]);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 0;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        },
        transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
        },
        getContentAnchorEl: null,
    };

    const fetchClients = async () => {
        try {
            const clientsCollection = collection(db, 'servicios', servicioId, 'clientes');
            const clientsSnapshot = await getDocs(clientsCollection);

            const clientsData = clientsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            setClients([{ id: 'all', nombre: 'Todos los clientes' }, ...clientsData]);
        } catch (error) {
            console.error('Error al obtener los clientes:', error);
        }
    };

    const fetchServices = async () => {
        try {
            const serviciosRef = collection(db, 'servicios', servicioId, 'tiendas');
            const serviciosSnapshot = await getDocs(serviciosRef);
            const serviciosData = serviciosSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            setServices(serviciosData);
        } catch (error) {
            console.error('Error al obtener los servicios:', error);
        }
    };

    useEffect(() => {
        if (!servicioId) {
            console.error('servicioId es undefined o null');
            return;
        }

        fetchClients();
        fetchServices();
    }, [servicioId]);

    const handleNotificationTitleChange = (e) => {
        setNotificationTitle(e.target.value);
    };

    const handleNotificationBodyChange = (e) => {
        setNotificationBody(e.target.value);
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();

        if (currentService) {
            if (selectedClient === 'all') {
                // Enviar notificación a todos los clientes
                sendNotification(notificationTitle, notificationBody, servicioId);
            } else if (selectedService) {
                // Enviar notificación a una tienda específica
                sendNotificationToStore(notificationTitle, notificationBody, selectedService, servicioId);
            } else {
                // Enviar notificación al cliente seleccionado
                sendNotification(notificationTitle, notificationBody, selectedClient);
            }

            setNotificationTitle('');
            setNotificationBody('');
        } else {
            console.error('No se ha encontrado un servicio asociado al usuario.');
        }
    };


    const handleClientChange = async (e) => {
        const selectedClientId = e.target.value;
        setSelectedClient(selectedClientId);

        console.log("select cliente id", selectedClientId)
        if (selectedClientId === 'all') {
            setServices([]); // Limpiar tiendas si se selecciona "Todos los clientes"
        } else if (selectedClientId) {
            try {
                // Obtener las tiendas filtradas por el cliente y servicio seleccionados
                const tiendasRef = collection(db, 'servicios', servicioId, 'tiendas');
                const tiendasQuery = query(
                    tiendasRef,
                    where('cliente', '==', selectedClientId)
                );
                const tiendasSnapshot = await getDocs(tiendasQuery);

                const tiendasData = tiendasSnapshot.docs.map((doc) => doc.data());

                setServices(tiendasData);
            } catch (error) {
                console.error('Error al obtener las tiendas:', error);
            }
        }
    };

    const handleServiceChange = (e) => {
        const selectedServiceIds = Array.from(e.target.value);
        setSelectedServices(selectedServiceIds);
    };

    return (
        <div className="notifications-container">
            <form className="notification-form" onSubmit={handleFormSubmit}>
                <h3 className="label-title-form">Enviar notificación</h3>
                <div className="form-group">
                    <label htmlFor="notificationTitle">Título de la notificación:</label>
                    <input
                        type="text"
                        id="notificationTitle"
                        value={notificationTitle}
                        onChange={handleNotificationTitleChange}
                        placeholder="Escribe el título de la notificación"
                        className="input-notis"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="notificationBody">Cuerpo de la notificación:</label>
                    <textarea
                        id="notificationBody"
                        value={notificationBody}
                        onChange={handleNotificationBodyChange}
                        placeholder="Escribe el cuerpo de la notificación"
                        rows="5"
                        cols="50"
                        className="input-notis"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="clientSelect">Selecciona el cliente:</label>
                    <select id="clientSelect" value={selectedClient} onChange={handleClientChange} className="input-notis">
                        <option value="">Selecciona un cliente</option>
                        {clients.map((client) => (
                            <option key={client.id} value={client.id}>
                                {client.nombre}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="serviceSelect">Selecciona el servicio:</label>
                    <Select
                        id="serviceSelect"
                        value={selectedServices}
                        onChange={handleServiceChange}
                        multiple
                        className="input-notis"
                        sx={{ background: 'white' }}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                    <Chip key={value} label={value} />
                                ))}
                            </Box>
                        )}
                        MenuProps={MenuProps}
                    >
                        <MenuItem value="">Selecciona un servicio</MenuItem>
                        {services.map((service) => (
                            <MenuItem key={service.id} value={service.numTienda}>
                                {service.numTienda}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
                <button type="submit" className="submit-button">
                    Enviar Notificación
                </button>
            </form>
        </div>
    );
};

export default NotificationsCombined;
