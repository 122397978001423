// sidebarActionTypes.js
// Definimos los tipos de acciones como constantes para evitar errores de tipeo y para tener un mejor control.
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';

// sidebarActions.js
// Esta es una acción creadora, que es una función que crea una acción.
// TOGGLE_SIDEBAR podría ser una acción para abrir/cerrar la barra lateral.

export const toggleSidebar = () => {
  return {
    type: TOGGLE_SIDEBAR,
    // Aquí no necesitamos payload porque estamos simplemente alternando el estado.
  };
};
