import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { collection, getDocs, deleteDoc, doc, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";
import { Card, CardContent, CardActions, Button, IconButton } from "@mui/material";
import "./datatableClientes.scss";
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

const DatatableClientes = () => {
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      let list = [];
      try {
        // Obtén la lista de servicios
        const serviciosSnapshot = await getDocs(collection(db, "servicios"));

        for (const servicioDoc of serviciosSnapshot.docs) {
          // Por cada servicio, consulta los clientes
          const clientesSnapshot = await getDocs(
            collection(servicioDoc.ref, "clientes")
          );

          clientesSnapshot.forEach((clienteDoc) => {
            const clienteData = clienteDoc.data();
            if (!clienteData.borrado) {
              list.push({ id: clienteDoc.id, ...clienteData });
            }
          });
        }

        setData(list);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();

    const unsub = onSnapshot(
      collection(db, "servicios"),
      async (serviciosSnapshot) => {
        let list = [];

        for (const servicioDoc of serviciosSnapshot.docs) {
          const clientesSnapshot = await getDocs(
            collection(servicioDoc.ref, "clientes")
          );

          clientesSnapshot.forEach((clienteDoc) => {
            const clienteData = clienteDoc.data();
            if (!clienteData.borrado) {
              list.push({ id: clienteDoc.id, ...clienteData });
            }
          });
        }

        setData(list);
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsub();
    };
  }, []);

  const handleDelete = async (id) => {
    try {
      const confirmDelete = window.confirm(
        "¿Estás seguro de que deseas eliminar este cliente?"
      );
      if (confirmDelete) {
        // Encuentra y elimina el cliente en todos los servicios
        const serviciosSnapshot = await getDocs(collection(db, "servicios"));

        for (const servicioDoc of serviciosSnapshot.docs) {
          const clienteRef = doc(servicioDoc.ref, "clientes", id);
          await deleteDoc(clienteRef);
        }

        setData(data.filter((item) => item.id !== id));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const filteredData = data.filter((row) => {
    const rowValues = Object.values(row).join("").toLowerCase();
    return rowValues.includes(filter.toLowerCase());
  });

  const renderClienteCard = (cliente) => {
    return (
      <Card key={cliente.id} className="clienteCard">
        <CardContent>
          <img src={cliente.img} alt="Logo" className="clienteLogo" />
          <div className="clienteInfo">
            <div className="nombreServicio">{cliente.nombre}</div>
            <div className="contactoLogos">
              <IconButton className="iconContainer">
                <MailOutlineIcon className="whiteIcon" /> {/* Ícono de mail */}
              </IconButton>
              <IconButton size="small" className="iconContainer">
                <PhoneEnabledIcon className="whiteIcon" /> {/* Ícono de teléfono */}
              </IconButton>
            </div>    
          </div>
          <div className="idServicio">{cliente.id}</div>
        </CardContent>
        <hr></hr>
        <CardActions className="acciones">
          <Link to={`/clientes/${encodeURIComponent(cliente.id)}/tiendas/${encodeURIComponent(cliente.nombre)}`}>
            <Button size="small" className="verButton">Ver</Button>
          </Link>
          <div className="rightActions">
          <Link to={`/clientes/update/${encodeURIComponent(cliente.id)}`}>
              <IconButton size="small">
                <EditIcon className="editarIcon" /> {/* Ícono de editar */}
              </IconButton>
            </Link>
            <IconButton size="small" color="secondary" onClick={() => handleDelete(cliente.id)}>
              <DeleteIcon className="borrarIcon" /> {/* Ícono de borrar */}
            </IconButton>
          </div>
        </CardActions>
      </Card>
    );
  };

  const columns = [
    {
      field: 'cliente',
      headerName: 'Cliente',
      flex: 1,
      renderCell: renderClienteCard
    }
  ];

  return (
    <div className="datatable">
      <div className="datatableTitle">
        <h2 className="title-registro">Registro Clientes</h2>
        <div className="rightActions">
          <div className="searchContainer">
            <input
              type="text"
              placeholder="Buscar"
              className="searchInput"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
            <SearchIcon className="searchIcon" /> {/* Ícono de lupa usando MUI */}
          </div>
          <Link to="/clientes/newCliente" className="link">
            Nuevo cliente
          </Link>
        </div>
      </div>
      <div className="cardsContainer">
        {filteredData.map(renderClienteCard)}
        {/* {Array(6).fill(null).map((_, index) => renderClienteCard(filteredData[0]))} 
        Esto es para probar como quedarían varias cajas, genera duplicados.*/} 
      </div>
    </div>
  );
};

export default DatatableClientes;