import React, { useState } from "react";
import './servicio.scss'
import { collection, addDoc } from "firebase/firestore";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../../firebase";
import { Link } from "react-router-dom";
import { uploadLogToFirestore } from "../../utils/logUtils";

/**
 * Componente para registrar un nuevo servicio y usuario.
 *
 * @returns {JSX.Element} - Componente Servicio.
 */
const Servicio = () => {
  const [nombre, setNombre] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  /**
 * Maneja el evento de registro del servicio y usuario.
 *
 * @param {Object} e - Evento del formulario.
 */
  const handleRegisterService = async (e) => {
    e.preventDefault();

    try {
      console.log("Attempting to create user...");
      // Crear el usuario primero
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      // Obtener el ID del usuario recién creado
      const userId = userCredential.user.uid;

      // Crear un nuevo documento de servicio en la colección "servicios"
      const nuevoServicio = {
        nombre: nombre,
        userId: userId,
      };

      console.log("Attempting to add new service...");
      // Agregar el servicio a la colección "servicios"
      const servicioDocRef = await addDoc(
        collection(db, "servicios"),
        nuevoServicio
      );


      // Inicializar la colección "vigilantes" dentro del servicio recién registrado
      const vigilantesCollectionRef = collection(servicioDocRef, "vigilantes");
      await addDoc(vigilantesCollectionRef, { /* datos del vigilante */ });

      // Inicializar la colección "tiendas" dentro del servicio recién registrado
      const tiendasCollectionRef = collection(servicioDocRef, "tiendas");
      await addDoc(tiendasCollectionRef, { /* datos de la tienda */ });

      // Inicializar la colección "documentos" dentro del servicio recién registrado
      const documentsCollectionRef = collection(servicioDocRef, "documents");
      await addDoc(documentsCollectionRef, { /* datos de la tienda */ });

      // Inicializar la colección "clientes" dentro del servicio recién registrado
      const clientesCollectionRef = collection(servicioDocRef, "clientes");
      await addDoc(clientesCollectionRef, { /* datos de la tienda */ });

      console.log("Nuevo servicio registrado con ID:", servicioDocRef.id);
      //registro log en la creación de servicio
      uploadLogToFirestore("Nuevo servicio registrado con ID: " + servicioDocRef.id);
      // Restablecer los campos del formulario después de registrar el servicio y el usuario
      setNombre("");
      setEmail("");
      setPassword("");
      // Realizar otras acciones después del registro del servicio y el usuario si es necesario
    } catch (error) {
      console.log("Error al registrar el nuevo servicio y usuario:", error);
      // Manejar el error de registro del servicio y el usuario si es necesario
      uploadLogToFirestore("Error al registrar el nuevo servicio y usuario: " + error)
    }
  };

  return (
    <div className="servicio">
      <h1 data-testid="servicio-header">Registro de Servicio y Usuario</h1>
      <form onSubmit={handleRegisterService}>
        <input
          type="text"
          placeholder="Nombre del servicio"
          value={nombre}
          onChange={(e) => setNombre(e.target.value)}
          className="input"
        />
        <input
          type="email"
          placeholder="Correo electrónico"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="input"
        />
        <input
          type="password"
          placeholder="Contraseña"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="input"
        />
        {/* Otros campos del formulario */}
        <button type="submit" className="button">Registrar Servicio y Usuario</button>
      </form>
      <Link to="/" className="link">Volver</Link>
    </div>
  );
};

export default Servicio;
