import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import List from "./pages/list/List";
import ListTiendas from "./pages/list/ListTiendas";
import ListClientes from "./pages/list/ListClientes";
import ListTiendasCliente from "./pages/list/ListTiendasCliente";
import Single from "./pages/single/Single";
import Details from "./pages/details/Details";
import Documents from "./pages/documents/Documents";
import Trash from "./pages/documents/DeletedDocuments";
import Servicio from "./pages/servicio/Servicio.jsx";
import UpdateServicio from "./pages/servicio/UpdateServicio.jsx";
import New from "./pages/new/New";
import Update from "./pages/update/Update";
import UpdateTienda from "./pages/update/UpdateTienda";
import UpdateCliente from "./pages/update/UpdateCliente";
import NewTienda from "./pages/new/NewTienda";
import NewCliente from "./pages/new/NewCliente";
import NewClienteTienda from "./pages/new/NewClienteTienda";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { clientesInputs, productInputs, userInputs } from "./formSource";
import "./style/dark.scss";
import { useContext } from "react";
import { SidebarStateProvider }  from "./components/sidebar/SidebarStateProvider.js"
import { AuthContext } from "./context/AuthContext";
import { AuthContextProvider } from "./context/AuthContext";
import PrivacyPolicy from './components//infoBox/PrivacyPolicy';
import Forms from "./pages/list/Forms";
import FormsTable from "./pages/list/FormsTable";
import FormsUpdate from "./pages/update/UpdateForm";
import NotificationsForm from "./pages/list/NotificationsForm";

/**
 * Componente principal de la aplicación.
 */
function App() {
  const { currentUser } = useContext(AuthContext)

  /**
 * Componente de ruta protegida que requiere autenticación.
 * Redirige al usuario a la página de inicio de sesión si no está autenticado.
 *
 * @param {Object} children - Componente anidado dentro de RequireAuth.
 * @returns {JSX.Element} - Componente RequireAuth.
 */
  const RequireAuth = ({ children }) => {
    return currentUser ? children : <Navigate to="/login" />;
  };

  return (
    <div className="app">
      <BrowserRouter>
        <AuthContextProvider>
          <SidebarStateProvider>

            <Routes>
              <Route path="/">
                <Route path="login" element={<Login />} />
                <Route path="/servicio" element={<Servicio />} />
                <Route path="/UpdateServicio" element={<UpdateServicio />} />
                <Route
                  index
                  element={
                    <RequireAuth>
                      <Home />
                    </RequireAuth>
                  }
                />
                <Route path="vigilantes">
                  <Route
                    index
                    element={
                      <RequireAuth>
                        <List />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path=":tipVS"
                    element={
                      <RequireAuth>
                        <Single />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="details/:id"
                    element={
                      <RequireAuth>
                        <Details />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="new"
                    element={
                      <RequireAuth>
                        <New inputs={userInputs} title="Añadir vigilante" />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="update/:tipVS"
                    element={
                      <RequireAuth>
                        <Update inputs={userInputs} title="Actualizar vigilante" />
                      </RequireAuth>
                    }
                  />
                </Route>
                <Route path="tiendas">
                  <Route
                    index
                    element={
                      <RequireAuth>
                        <ListTiendas />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path=":tiendasId"
                    element={
                      <RequireAuth>
                        <Single />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="newTienda"
                    element={
                      <RequireAuth>
                        <NewTienda inputs={productInputs} title="Añadir nueva tienda" />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="update/:tiendaId"
                    element={
                      <RequireAuth>
                        <UpdateTienda inputs={productInputs} title="Actualizar tienda" />
                      </RequireAuth>
                    }
                  />
                </Route>
                <Route path="clientes">
                  <Route
                    index
                    element={
                      <RequireAuth>
                        <ListClientes />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/clientes/:clienteId/tiendas/:nombreCliente"
                    element={
                      <RequireAuth>
                        <ListTiendasCliente />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="newCliente"
                    element={
                      <RequireAuth>
                        <NewCliente inputs={clientesInputs} title="Añadir nuevo cliente" />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/clientes/:clienteId/newClienteTienda/:nombreCliente"
                    element={
                      <RequireAuth>
                        <NewClienteTienda inputs={productInputs} title="Añadir nueva tienda" />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="update/:clienteId"
                    element={
                      <RequireAuth>
                        <UpdateCliente inputs={clientesInputs} title="Actualizar cliente" />
                      </RequireAuth>
                    }
                  />
                </Route>
              </Route>
              <Route path="documentos">
                <Route
                  index
                  element={
                    <RequireAuth>
                      <Documents />
                    </RequireAuth>
                  }
                />
              </Route>
              <Route path="papelera">
                <Route
                  index
                  element={
                    <RequireAuth>
                      <Trash />
                    </RequireAuth>
                  }
                />
              </Route>
              <Route path="notificaciones">
                <Route
                  index
                  element={
                    <RequireAuth>
                      <NotificationsForm />
                    </RequireAuth>
                  }
                />
              </Route>
              <Route path="form-table">
                <Route
                  index
                  element={
                    <RequireAuth>
                      <FormsTable />
                    </RequireAuth>
                  }
                />
              </Route>
              <Route path="form-creator">
                <Route
                  index
                  element={
                    <RequireAuth>
                      <Forms />
                    </RequireAuth>
                  }
                />
              </Route>
              <Route path="form-update/:id">
                <Route
                  index
                  element={
                    <RequireAuth>
                      <FormsUpdate />
                    </RequireAuth>
                  }
                />
              </Route>
            </Routes>
          </SidebarStateProvider>
        </AuthContextProvider>
        <Routes>
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
