import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { userColumns, userRows, tiendasColumns } from "../../datatablesource";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  deleteDoc,
  updateDoc,
  doc,
  onSnapshot,
  where,
  query,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import customLocaleText from './LocaleText.js'
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

/**
 * Componente para mostrar una tabla de datos.
 */
const Datatable = () => {
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [showDeleted, setShowDeleted] = useState(false);

  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    /**
   * Función asíncrona para cargar datos desde Firebase.
   */
    const fetchData = async () => {
      try {
        const serviciosRef = collection(db, "servicios");
        const q = query(serviciosRef, where('admins', 'array-contains', currentUser.email));
        const serviciosSnapshot = await getDocs(q);
        const list = [];

        for (const servicioDoc of serviciosSnapshot.docs) {
          const vigilantesSnapshot = await getDocs(collection(servicioDoc.ref, "vigilantes"));
          vigilantesSnapshot.forEach((vigilanteDoc) => {
            const vigilanteData = vigilanteDoc.data();
            list.push({ id: vigilanteDoc.id, ...vigilanteData });
          });
        }
        setData(list);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, [currentUser]);

  useEffect(() => {
    const updateFilteredData = () => {
      /**
     * Función para actualizar los datos filtrados.
     */
      const updatedFilteredData = data
        .filter((row) => {
          const rowValues = Object.values(row).join('').toLowerCase();
          return rowValues.includes(filter.toLowerCase());
        })
        .filter((row) => {
          if (showDeleted) {
            return row.borrado; // Mostrar solo los registros borrados si showDeleted es true
          } else {
            return !row.borrado; // Mostrar solo los registros no borrados si showDeleted es false
          }
        })
        .map((row) => {
          if (Array.isArray(row.tiendas)) {
            return { ...row, tiendas: row.tiendas.map((tienda) => `${tienda.numero}`) };
          } else {
            return row;
          }
        });
      setFilteredData(updatedFilteredData);
    };

    updateFilteredData();
  }, [data, filter, showDeleted]);


  /**
 * Maneja el evento de eliminación de un vigilante.
 * @param {string} id - ID del vigilante a eliminar.
 */
  const handleDelete = async (id) => {
    const confirmDelete = window.confirm("¿Estás seguro de que deseas eliminar este vigilante?");
    if (confirmDelete) {
      try {
        const servicioSnapshot = await getDocs(collection(db, "servicios"));
        servicioSnapshot.forEach(async (servicioDoc) => {
          const vigilanteRef = doc(servicioDoc.ref, "vigilantes", id);
          await updateDoc(vigilanteRef, { borrado: true });
        });
        const updatedData = data.map((item) => item.id === id ? { ...item, borrado: true } : item);
        setData(updatedData);
      } catch (err) {
        console.log(err);
      }
    }
  };

  /**
 * Maneja el cambio en la casilla de verificación "Mostrar borrados".
 * @param {object} event - Evento de cambio.
 */
  const handleShowDeletedChange = (event) => {
    setShowDeleted(event.target.checked);
  };

  /**
 * Maneja el evento de restauración de un vigilante.
 * @param {string} id - ID del vigilante a restaurar.
 */
  const handleRestore = async (id) => {
    const confirmRestore = window.confirm("¿Estás seguro de que deseas recuperar este vigilante?");
    if (confirmRestore) {
      try {
        const servicioSnapshot = await getDocs(collection(db, "servicios"));
        servicioSnapshot.forEach(async (servicioDoc) => {
          const vigilanteRef = doc(servicioDoc.ref, "vigilantes", id);
          await updateDoc(vigilanteRef, { borrado: false }); // Marcar como no borrado
        });
        const updatedData = data.map((item) => (item.id === id ? { ...item, borrado: false } : item));
        setData(updatedData);
        setShowDeleted(false); // Actualiza el estado de showDeleted
      } catch (err) {
        console.log(err);
      }
    }
  };


    const actionColumn = {
      field: "action",
      headerName: "Action",
      width: 250,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link
              to={`/vigilantes/${encodeURIComponent(params.row.tipVS)}`}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">Ver</div>
            </Link>
            <Link to={`/vigilantes/update/${encodeURIComponent(params.row.tipVS)}`}
              style={{ textDecoration: "none" }}>
              <EditIcon className="editIcon" /> {/* Ícono de lápiz para editar */}
            </Link>
            {params.row.borrado ? (
              <div
                className="viewButton"
                onClick={() => handleRestore(params.row.id)}
              >
                Recuperar
              </div>
            ) : null}
            {!params.row.borrado ? (
              <DeleteIcon className="deleteIcon" onClick={() => handleDelete(params.row.id)} /> 
            ) : null}
          </div>
        );
      },
  };
  

  return (
    <div className="datatable">
      <div className="datatableTitle">
        <h2 className="title-registro">Registro Vigilantes</h2>
        <div className="rightActions">
          <FormControlLabel
            control={
              <Checkbox
                checked={showDeleted}
                onChange={handleShowDeletedChange}
                color="primary"
              />
            }
            label="Mostrar borrados"
          />
          <div className="searchContainer">
            <input
              type="text"
              placeholder="Buscar"
              className="searchInput"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
            <SearchIcon className="searchIcon" /> {/* Ícono de lupa usando MUI */}
          </div>
          <Link to="/vigilantes/new" className="link">
            Nuevo perfil
          </Link>
        </div>
      </div>
      <DataGrid
        className="datagrid"
        rows={filteredData}
        columns={userColumns.concat(actionColumn)}
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection
        localeText={customLocaleText}
      />
    </div>
  );
};

export default Datatable;