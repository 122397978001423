import { db } from '../firebase';
import { collection, addDoc } from "firebase/firestore"; 

// Función para subir un registro a la colección "logs" en Firestore
export const uploadLogToFirestore = async (logContent) => {
    try {
        const timestamp = new Date();

        // Referencia a la colección "logs" en Firestore
        const logsCollectionRef = collection(db, 'logs');

        // Agrega el registro a la colección "logs"
        await addDoc(logsCollectionRef, {
            timestamp: timestamp,
            content: logContent,
            logtype: 'info',
        });


        console.log('Log uploaded to Firestore.');
    } catch (error) {
        console.error('Error uploading log to Firestore:', error);
    }
};

// Función para subir un registro a la colección "logs" en Firestore
export const uploadErrorLogToFirestore = async (logContent) => {
    try {
        const timestamp = new Date();

        // Referencia a la colección "logs" en Firestore
        const logsCollectionRef = collection(db, 'logs');

        // Agrega el registro a la colección "logs"
        await addDoc(logsCollectionRef, {
            timestamp: timestamp,
            content: logContent,
            logtype: 'error',
        });


        console.log('Log uploaded to Firestore.');
    } catch (error) {
        console.error('Error uploading log to Firestore:', error);
    }
};
