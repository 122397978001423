import { db } from '../../firebase';
import axios from 'axios';
import { collection, getDocs } from 'firebase/firestore';
import { addNotification } from './firebaseNotifications';

export const getSubIDsForVigilantes = async (numTiendas, servicioId) => {
    try {
        if (!servicioId) {
            console.error('No se ha encontrado un servicio asociado al usuario.');
            return [];
        }

        const vigilantesRef = collection(db, 'servicios', servicioId, 'vigilantes');
        const vigilantesSnapshot = await getDocs(vigilantesRef);

        const subIDsSet = new Set(); // Utilizamos un Set para evitar duplicados
        vigilantesSnapshot.docs.forEach(doc => {
            const tiendas = doc.data().tiendas || [];
            tiendas.forEach(tienda => {
                if (tienda && numTiendas.includes(tienda.numero)) {
                    subIDsSet.add(doc.data().tipVS);
                }
            });
        });

        // Convertimos el Set a un array para retornarlo
        const uniqueSubIDs = Array.from(subIDsSet);

        return uniqueSubIDs;
    } catch (error) {
        console.error('Error al obtener subIDs de vigilantes:', error);
        return [];
    }
};



export const sendNotificationToStore = async (notificationTitle, notificationMessage, numTienda, servicioId) => {
    try {
        const subIDsDeTienda = await getSubIDsForVigilantes(numTienda, servicioId);

        const notificationBody = {
            subIDs: subIDsDeTienda,
            appId: process.env.REACT_APP_APP_ID,
            appToken: process.env.REACT_APP_APP_TOKEN,
            title: notificationTitle,
            message: notificationMessage,
        };

        await axios.post('https://app.nativenotify.com/api/indie/group/notification', notificationBody);

        const to = determineToValue(numTienda);
        addNotification(notificationBody.title, notificationBody.message, to, new Date().toISOString(), servicioId)

    } catch (error) {
        console.error('Error al obtener subIDs y enviar notificación:', error);
    }
};

const determineToValue = (numTienda) => {
    const numTiendaArray = Array.isArray(numTienda) ? numTienda : [numTienda];
    return numTiendaArray.length > 1 ? 'cliente' : 'servicio';
};