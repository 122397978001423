/**
 * Reducer para el contexto de autenticación.
 * @param {Object} state - Estado actual del contexto.
 * @param {Object} action - Acción a ejecutar en el reducer.
 * @param {string} action.type - Tipo de acción.
 * @param {*} action.payload - Carga útil de la acción.
 * @returns {Object} - Nuevo estado del contexto.
 */

const AuthReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        currentUser: action.payload,
      };
    case "LOGOUT":
      return {
        ...state,
        currentUser: null,
        currentService: null, // Agrega esta línea para limpiar el servicio al hacer logout
      };
    case "SET_CURRENT_SERVICE":
      return {
        ...state,
        currentService: action.payload,
      };
    default:
      return state;
  }
};

export default AuthReducer;

