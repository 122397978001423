import React, { useState, useEffect, useContext } from "react";
import {
    collection, doc, updateDoc,
    getDocs, where, query, deleteDoc
} from "firebase/firestore";
import { db } from "../../firebase";
import { AuthContext } from "../../context/AuthContext";
import { DataGrid } from "@mui/x-data-grid";
import Checkbox from "@mui/material/Checkbox";
import "./table.scss";
import { useParams, useNavigate, Link } from "react-router-dom";
import { uploadLogToFirestore } from "../../utils/logUtils";
import customLocaleText  from '../datatable/LocaleText'
import DeleteIcon from '@mui/icons-material/Delete';

/**
 * Componente para mostrar y gestionar la lista de documentos borrados.
 *
 * Este componente muestra una lista de documentos que han sido marcados como borrados. Permite filtrar y buscar
 * documentos, restaurar documentos seleccionados y borrar documentos permanentemente después de 30 días.
 *
 * @component
 * @returns {JSX.Element} Elemento de React que muestra la lista de documentos borrados.
 */
const DeletedDocumentsList = () => {
    const { currentUser } = useContext(AuthContext);
    const [deletedDocuments, setDeletedDocuments] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [allRowsSelected, setAllRowsSelected] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [filterDate, setFilterDate] = useState("");
    const [filterVigilante, setFilterVigilante] = useState("");
    const [searchTienda, setSearchTienda] = useState("");
    const [nombresLoaded, setNombresLoaded] = useState(false);

    useEffect(() => {
        fetchDeletedDocuments();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            if (deletedDocuments.length > 0 && !nombresLoaded) {
                await populateNombre();
                setNombresLoaded(true);
            }
        };

        fetchData();
    }, [deletedDocuments, nombresLoaded]);


    useEffect(() => {
        // Call the automatic deletion function every 24 hours (86400000 milliseconds)
        const intervalId = setInterval(handleAutomaticDeletion, 86400000); //1800000 30 minutos para test

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    /**
  * Recupera y agrega el nombre del vigilante a cada documento.
  *
  * @async
  * @function
  */
    const populateNombre = async () => {
        if (nombresLoaded) return;

        try {
            const serviciosRef = collection(db, 'servicios');
            const q = query(serviciosRef, where('admins', 'array-contains', currentUser.email));
            const querySnapshot = await getDocs(q);
            if (!querySnapshot.empty) {
                const servicioDoc = querySnapshot.docs[0];
                const servicioData = servicioDoc.data();
                const servicioId = servicioDoc.id;

                const documentsWithNombre = await Promise.all(
                    deletedDocuments.map(async (document) => {
                        try {
                            if (!document.vigilante) {
                                console.log('Missing vigilante property in document:', document);
                                return document;
                            }

                            const vigilanteId = document.vigilante.replace('@gmail.com', '');

                            const vigilantesQuerySnapshot = await getDocs(query(collection(db, 'servicios', servicioId, 'vigilantes'), where('tipVS', '==', vigilanteId)));

                            if (!vigilantesQuerySnapshot.empty) {
                                const vigilanteData = vigilantesQuerySnapshot.docs[0].data();

                                const nombre = vigilanteData.nombre;
                                const apellidos = vigilanteData.apellidos;

                                const nombreCompleto = `${nombre} ${apellidos}`;
                                return { ...document, nombre: nombreCompleto };
                            } else {
                                return document;
                            }
                        } catch (error) {
                            console.error('Error en promesa individual:', error);
                            return document;
                        }
                    })
                );

                setDeletedDocuments(documentsWithNombre);
                setNombresLoaded(true);
            }
        } catch (error) {
            console.error('Error al obtener los documentos:', error);
        }
    };

    /**
     * Recupera los documentos borrados y los almacena en el estado.
     *
     * @async
     * @function
     */
    const fetchDeletedDocuments = async () => {
        try {
            const serviciosRef = collection(db, 'servicios');
            const q = query(serviciosRef, where('admins', 'array-contains', currentUser.email));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                const servicioDoc = querySnapshot.docs[0];
                const servicioData = servicioDoc.data();
                const servicioId = servicioDoc.id;

                const documentsRef = collection(serviciosRef, servicioId, 'documents');
                const documentsSnapshot = await getDocs(documentsRef);

                const deletedDocs = documentsSnapshot.docs
                    .map((doc) => {
                        const documentData = doc.data();
                        const timestamp = documentData.fecha;
                        if (timestamp) {
                            const seconds = timestamp.seconds;
                            const nanoseconds = timestamp.nanoseconds;
                            const date = new Date(seconds * 1000 + nanoseconds / 1000000);
                            const dia = date.getDate();
                            const mes = date.getMonth() + 1;
                            const año = date.getFullYear();
                            const fechaFormateada = `${dia}/${mes}/${año}`;
                            documentData.fecha = fechaFormateada;
                        }
                        return { id: doc.id, ...documentData };
                    })
                    .filter((document) => document.borrado); // Filtrar documentos borrados

                setDeletedDocuments(deletedDocs);
            }
        } catch (error) {
            console.error('Error al obtener los documentos:', error);
            // uploadLogToFirestore('Error al obtener los documentos: ' + error);
        }
    };

    /**
 * Realiza la eliminación automática de documentos que han estado marcados como borrados durante más de 30 días.
 *
 * @async
 * @function
 */
    const handleAutomaticDeletion = async () => {
        try {
            const currentTime = new Date();
            console.log(currentTime)
            // new Date(currentTime.getTime() - 30 * 60 * 1000); 30 minutos // new Date(currentTime.getTime() - 30 * 24 * 60 * 60 * 1000); 30 días
            const thirtyDaysAgo = new Date(currentTime.getTime() - 30 * 24 * 60 * 60 * 1000);
            console.log(thirtyDaysAgo)
            thirtyDaysAgo.setDate(currentTime.getDate() - 30);

            const serviciosRef = collection(db, "servicios");
            const q = query(
                serviciosRef,
                where("admins", "array-contains", currentUser.email)
            );
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                const servicioDoc = querySnapshot.docs[0];
                const servicioData = servicioDoc.data();
                const servicioId = servicioDoc.id;

                const documentsRef = collection(serviciosRef, servicioId, "documents");
                const documentsSnapshot = await getDocs(documentsRef);

                for (const doc of documentsSnapshot.docs) {
                    const documentData = doc.data();
                    if (documentData.borrado === true) {
                        const timestamp = documentData.borradoTime;
                        if (timestamp) {
                            const seconds = timestamp.seconds;
                            const nanoseconds = timestamp.nanoseconds;
                            const deletionDate = new Date(seconds * 1000 + nanoseconds / 1000000);

                            if (deletionDate <= currentTime) {
                                // Delete the document permanently
                                await deleteDoc(doc.ref);
                                uploadLogToFirestore(doc.ref, ' documento borrado a las: ', currentTime)
                            }
                        }
                    }
                }
            }
        } catch (error) {
            console.error("Error during automatic deletion:", error);
        }
    };

    /**
     * Maneja la selección/deselección de todas las filas.
     *
     * @param {object} event - Evento de cambio.
     * @function
     */
    const handleSelectAllRows = (event) => {
        if (event.target.checked) {
            const allDocumentIds = deletedDocuments.map((doc) => doc.id);
            setSelectedRows(allDocumentIds);
            setAllRowsSelected(true);
        } else {
            setSelectedRows([]);
            setAllRowsSelected(false);
        }
    };

    /**
 * Filtra las filas de documentos borrados según los criterios de búsqueda y filtros.
 *
 * @param {object} document - Documento a evaluar.
 * @returns {boolean} Indicador de si el documento coincide con los criterios de búsqueda y filtros.
 * @function
 */

    const filteredRows = deletedDocuments.filter((document) => {
        console.log('Filter values:', searchTerm, filterDate, searchTienda, filterVigilante);

        console.log(document.documentTypeId, document.fecha, document.tienda, document.nombre)
        const isMatchingSearchTerm = document.documentTypeId?.toLowerCase().includes(searchTerm.toLowerCase()) ?? false;
        const isMatchingFecha = document.fecha?.toLowerCase().includes(filterDate.toLowerCase()) ?? false;
        const isMatchingTienda = !document.tienda || document.tienda?.toLowerCase().includes(searchTienda.toLowerCase());
        // const isMatchingVigilante = document.nombre?.toLowerCase().includes(filterVigilante.toLowerCase());

        // Combine the individual filters using logical AND (&&)
        return (
            isMatchingSearchTerm &&
            isMatchingFecha &&
            isMatchingTienda
            //  &&
            // isMatchingVigilante
        );
    });

    /**
     * Maneja la selección/deselección de una fila.
     *
     * @param {object} event - Evento de cambio.
     * @param {string} documentId - ID del documento seleccionado.
     * @function
     */
    const handleRowSelection = (event, documentId) => {
        if (event.target.checked) {
            setSelectedRows([...selectedRows, documentId]);
        } else {
            setSelectedRows(selectedRows.filter((id) => id !== documentId));
        }
    };

    /**
 * Restaura los documentos seleccionados marcando su estado como no borrado.
 *
 * @async
 * @function
 */
    const handleRestoreSelected = async () => {
        try {
            const serviciosRef = collection(db, "servicios");
            const q = query(
                serviciosRef,
                where("admins", "array-contains", currentUser.email)
            );
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                const servicioDoc = querySnapshot.docs[0];
                const servicioData = servicioDoc.data();
                const servicioId = servicioDoc.id;

                for (const documentId of selectedRows) {
                    const documentRef = doc(
                        serviciosRef,
                        servicioId,
                        "documents",
                        documentId
                    );

                    await updateDoc(documentRef, { borrado: false });
                }

                // Remove the restored documents from the state
                setDeletedDocuments((prevDeletedDocs) =>
                    prevDeletedDocs.filter((doc) => !selectedRows.includes(doc.id))
                );

                setSelectedRows([]);
            }
        } catch (error) {
            console.error("Error al restaurar los documentos:", error);
        }
    };

    /**
 * Maneja el cambio en el campo de búsqueda.
 *
 * @param {object} event - Evento de cambio.
 * @function
 */
    const handleDeleteSelected = async () => {
        try {
            const serviciosRef = collection(db, "servicios");
            const q = query(
                serviciosRef,
                where("admins", "array-contains", currentUser.email)
            );
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                const servicioDoc = querySnapshot.docs[0];
                const servicioData = servicioDoc.data();
                const servicioId = servicioDoc.id;

                for (const documentId of selectedRows) {
                    const documentRef = doc(
                        serviciosRef,
                        servicioId,
                        "documents",
                        documentId
                    );

                    // Delete the document permanently
                    await deleteDoc(documentRef);
                }

                // Remove the deleted documents from the state
                setDeletedDocuments((prevDeletedDocs) =>
                    prevDeletedDocs.filter((doc) => !selectedRows.includes(doc.id))
                );

                setSelectedRows([]);
            }
        } catch (error) {
            console.error("Error al borrar los documentos:", error);
        }
    };

    /**
 * Maneja el cambio en el campo de búsqueda.
 *
 * @param {object} event - Evento de cambio.
 * @function
 */
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        console.log(event.target.value); // Verifica si el valor se actualiza
    };

    /**
 * Maneja el cambio en el campo de filtro de fecha.
 *
 * @param {object} event - Evento de cambio.
 * @function
 */
    const handleFilterChange = (event) => {
        setFilterDate(event.target.value);
        console.log(event.target.value); // Verifica si el valor se actualiza
    };

    /**
     * Maneja el cambio en el campo de filtro de vigilante.
     *
     * @param {object} event - Evento de cambio.
     * @function
     */
    const handleVigilanteFilterChange = (event) => {
        setFilterVigilante(event.target.value);
        console.log(event.target.value); // Verifica si el valor se actualiza
    };

    /**
     * Maneja el cambio en el campo de filtro de tienda.
     *
     * @param {object} event - Evento de cambio.
     * @function
     */
    const handleTiendaFilterChange = (event) => {
        setSearchTienda(event.target.value);
        console.log(event.target.value); // Verifica si el valor se actualiza
    };


    return (
        <div className="tableContainer">
            <h2 className="datatableTitle">Papelera</h2>
            <div className="filterBarWrapper">
                <div className="filterBar">
                    <input
                        type="text"
                        placeholder="Filtrar por tipo de reporte"
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                </div>
                <div className="filterBar">
                    <input
                        type="text"
                        placeholder="Filtrar por fecha de subida"
                        value={filterDate}
                        onChange={handleFilterChange}
                    />
                </div>
                <div className="filterBar">
                    <input
                        type="text"
                        placeholder="Filtrar por tienda"
                        value={searchTienda}
                        onChange={handleTiendaFilterChange}
                    />
                </div>
                <div className="filterBar">
                    <input
                        type="text"
                        placeholder="Filtrar por vigilante"
                        value={filterVigilante}
                        onChange={handleVigilanteFilterChange}
                    />
                </div>
            </div>

            <div className="downloadButton">
                <button onClick={handleRestoreSelected} disabled={selectedRows.length === 0}>
                    Restaurar Seleccionados
                </button>
            </div>
            <DataGrid
                className="table"
                rows={filteredRows}
                pageSize={20}
                rowsPerPageOptions={[20]}
                columns={[
                    {
                        field: "selection",
                        headerName: "check",
                        headerClassName: "checkbox-header", // Add a class to style the header checkbox
                        width: 50,
                        disableColumnMenu: true,
                        sortable: false,
                        renderHeader: (params) => (
                            <Checkbox
                                checked={allRowsSelected}
                                indeterminate={
                                    selectedRows.length > 0 && selectedRows.length < filteredRows.length
                                }
                                onChange={handleSelectAllRows}
                            />
                        ),
                        renderCell: (params) => (
                            <Checkbox
                                checked={selectedRows.includes(params.id)}
                                onChange={(event) => handleRowSelection(event, params.id)}
                            />
                        ),
                    },
                    {
                        field: "documentTypeId",
                        headerName: "Tipo de reporte",
                        flex: 1,
                    },
                    {
                        field: "fecha",
                        headerName: "Fecha subida",
                        flex: 1,
                    },
                    {
                        field: "tipVS",
                        headerName: "Vigilante",
                        flex: 1,
                        valueGetter: (params) => params.row.vigilante.replace('@gmail.com', ''),
                    },
                    {
                        field: "nombre",
                        headerName: "Nombre",
                        flex: 1,
                    },
                    {
                        field: "tienda",
                        headerName: "Servicios",
                        flex: 1,
                    },
                    {
                        field: "fields.FECHA",
                        headerName: "Fecha de suceso",
                        flex: 1,
                        valueGetter: (params) => params.row.fields.FECHA || 'fecha no registrada',
                    },
                    {
                        field: "actions",
                        headerName: "Acciones",
                        width: 250,
                        renderCell: (params) => (
                            <div className="cellAction">
                                <Link to={`/vigilantes/details/${params.id}`}>
                                    <div className="viewButton">Ver</div>
                                </Link>
                                <div >
                                    <button className="editButton" onClick={handleRestoreSelected} disabled={selectedRows.length === 0}>
                                        Restaurar
                                    </button>
                                </div>
                                <DeleteIcon className="deleteIcon" onClick={handleDeleteSelected} disabled={selectedRows.length === 0} />
                            </div>
                        ),
                    },
                ]}
                autoHeight
                disableSelectionOnClick
                localeText={customLocaleText}
            />
        </div>
    );
};

export default DeletedDocumentsList;
