import React from 'react';
import './updateBox.scss';
import updatesData from './updatesData.json';  // Ajusta la ruta según tu estructura

const UpdateBox = () => {
    return (
        <div className="update-box">
            <div className="title"><h2>Actualizaciones</h2></div>
            {updatesData.map((update, index) => (
                <div key={index} className="update">
                    <div className="update-content">
                        <div className="update-title">{update.title}</div>
                        <div className="update-date">{update.date}</div>
                    </div>
                    <div className="update-text">
                        <ul>
                            {update.content.map((item, idx) => (
                                <li key={idx} dangerouslySetInnerHTML={{ __html: item }}></li>
                            ))}
                        </ul>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default UpdateBox;
