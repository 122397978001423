// SidebarStateContext.js
import { createContext, useContext } from "react";

export const SidebarStateContext = createContext();

export const useSidebarState = () => {
  const context = useContext(SidebarStateContext);
  if (context === undefined) {
    throw new Error('useSidebarState must be used within a SidebarStateProvider');
  }
  return context;
};

