/**
 * Formatea una fecha en formato legible.
 *
 * @param {object} fecha - Objeto de fecha a formatear.
 * @returns {string} Fecha formateada.
 */
export const formatFecha = (fecha) => {
    if (fecha && typeof fecha === "object" && fecha.seconds && fecha.nanoseconds) {
      const dateObject = new Date(fecha.seconds * 1000);
      console.log("dateObject:", dateObject); // Agrega este registro para ver el valor de dateObject
      console.log("formattedDate:", dateObject.toLocaleString()); // Agrega este registro para ver la fecha formateada
      return dateObject.toLocaleString();
    } else {
      return "";
    }
  };