import { useEffect, useReducer, createContext } from "react";
import AuthReducer from "./AuthReducer";
import  getCurrentService from "./GetCurrentService.js"

/**
 * Estado inicial del contexto de autenticación.
 * @type {Object}
 * @property {Object|null} currentUser - Usuario actualmente autenticado.
 * @property {Object|null} currentService - Servicio asociado al usuario actual.
 */
const INITIAL_STATE = {
  currentUser: JSON.parse(localStorage.getItem("user")) || null,
  currentService: null,
};

export const AuthContext = createContext(INITIAL_STATE);

/**
 * Proveedor de contexto de autenticación.
 * @param {Object} props - Propiedades del componente.
 * @param {Object} props.children - Componentes hijos.
 * @returns {JSX.Element} - Elemento de React que envuelve los componentes hijos con el contexto de autenticación.
 */
export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, INITIAL_STATE);
  const { currentUser } = state;

  useEffect(() => {
    const fetchCurrentService = async () => {
      if (currentUser) {
        try {
          // Obtener el servicio actual del usuario
          const currentService = await getCurrentService(currentUser);
          dispatch({ type: "SET_CURRENT_SERVICE", payload: currentService });
        } catch (error) {
          console.error("Error al obtener el servicio actual:", error);
        }
      }
    };

    fetchCurrentService();
  }, [currentUser]);

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(currentUser));
  }, [currentUser]);

  return (
    <AuthContext.Provider
      value={{ currentUser: state.currentUser, currentService: state.currentService, dispatch }}
    >
      {children}
    </AuthContext.Provider>
  );
};

