import {
    collection,
    doc,
    serverTimestamp,
    setDoc,
    getDocs,
    query,
    where,
    updateDoc
} from "firebase/firestore";
import { auth, db } from "../../../firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";

export const fetchTiendas = async (email) => {
    let list = [];
    try {
        const serviciosRef = collection(db, "servicios");
        const q = query(serviciosRef, where("admins", "array-contains", email));
        const serviciosSnapshot = await getDocs(q);
        for (const servicioDoc of serviciosSnapshot.docs) {
            const tiendasSnapshot = await getDocs(collection(servicioDoc.ref, "tiendas"));
            tiendasSnapshot.forEach((doc) => {
                list.push({ id: doc.id, ...doc.data() });
            });
        }
        list.sort((a, b) => a.numTienda - b.numTienda);
    } catch (err) {
        console.log(err);
    }
    return list;
};

export const fetchClientes = async (email) => {
    const serviciosRef = collection(db, "servicios");
    const q = query(serviciosRef, where("admins", "array-contains", email));
    const serviciosSnapshot = await getDocs(q);
    const listaClientes = [];
    for (const servicioDoc of serviciosSnapshot.docs) {
        const tiendasSnapshot = await getDocs(collection(servicioDoc.ref, "tiendas"));
        tiendasSnapshot.forEach((doc) => {
            const tiendaData = doc.data();
            const nombreCliente = tiendaData.nombreCliente;
            if (!listaClientes.includes(nombreCliente)) {
                listaClientes.push(nombreCliente);
            }
        });
    }
    return listaClientes;
};

export const registerVigilante = async (data, email) => {
    try {
        const servicioRef = collection(db, "servicios");
        const querySnapshot = await getDocs(query(
            servicioRef,
            where("admins", "array-contains", email)
        ));
        const servicioDoc = querySnapshot.docs[0];
        const vigilantesRef = collection(
            doc(servicioRef, servicioDoc.id),
            "vigilantes"
        );
        console.log('load data', data)
        const res = await createUserWithEmailAndPassword(
            auth,
            data.tipVS + "@gmail.com",
            data.dni
        );

        const servicioData = servicioDoc.data();
        if (!servicioData.users) {
            await setDoc(servicioDoc.ref, { users: [] });
        }

        const userTipVS = data.tipVS.toLowerCase();
        await updateDoc(servicioDoc.ref, {
            users: [userTipVS, ...servicioData.users],
        });

        await setDoc(doc(vigilantesRef, res.user.uid), {
            ...data,
            borrado: false,
            timeStamp: serverTimestamp(),
        });
        
        // Retornar un objeto con la propiedad 'success' cuando todo funcione correctamente
        return { success: true };
    } catch (err) {
        console.log(err);
        return { success: false };
    }
};
