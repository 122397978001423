import React, { useReducer } from "react";
import { SidebarStateContext } from "./SidebarStateContext";

const sidebarReducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_SIDEBAR":
      return { ...state, collapsed: !state.collapsed };
    default:
      return state;
  }
};

export const SidebarStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(sidebarReducer, { collapsed: false });

  return (
    <SidebarStateContext.Provider value={{ ...state, dispatch }}>
      {children}
    </SidebarStateContext.Provider>
  );
};
