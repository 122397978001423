import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { getFormsData, deleteForm } from './formHelpers/formHelper'; // Importa funciones auxiliares
import DataGridComponent from './formComponents/DataGridComponent'; // Importa componente de DataGrid
import { Link } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import './styles/formListScreen.scss';
import DeleteIcon from '@mui/icons-material/Delete';

/**
 * Componente que muestra una lista de formularios en un DataGrid con funcionalidad de búsqueda y acciones.
 *
 * @component
 * @returns {JSX.Element} - React JSX que representa el componente FormListScreen.
 */
const FormListScreen = () => {
  const { currentUser } = useContext(AuthContext);
  const [formularios, setFormularios] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [servicioId, setServicioId] = useState(null);

  /**
 * Función para obtener y establecer la información de los formularios al cargar el componente.
 *
 * @async
 * @function
 * @returns {Promise<void>} - Una promesa que se resuelve después de obtener y establecer la información de los formularios.
 */
  useEffect(() => {
    const fetchData = async () => {
      try {
        const formsData = await getFormsData(currentUser, setServicioId, setLoading);
        setFormularios(formsData);
      } catch (error) {
        console.error('Error al obtener los formularios:', error);
      }
    };

    if (currentUser) {
      fetchData();
    }
  }, [currentUser]);

  /**
 * Función para manejar la eliminación de un formulario.
 *
 * @async
 * @function
 * @param {string} id - El ID del formulario a eliminar.
 * @returns {Promise<void>} - Una promesa que se resuelve después de eliminar el formulario.
 */
  const handleDelete = async (id) => {
    await deleteForm(id, servicioId, setFormularios);
  };


  const columns = [
    { field: 'id', headerName: 'ID', width: 200, hide: true },
    { field: 'name', headerName: 'Nombre del Formulario', width: 200 },
    {
      field: 'export',
      headerName: 'Tipo de exportación',
      width: 150,
      renderCell: (params) => (
        <div style={{
          borderRadius: '10px',
          background: 'rgba(73, 80, 87, 0.27)',
          padding: '5px 10px',
          color: 'black',
          whiteSpace: 'nowrap'  // Esto evita que los elementos se rompan en varias líneas
        }}>
          {params.value}
        </div>
      ),
    },
    {
      field: 'cliente',
      headerName: 'Clientes',
      width: 200,
      renderCell: (params) => (
        <span>{Array.isArray(params.value) ? params.value.join(', ') : ''}</span>
      ),
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      width: 200,
      renderCell: (params) => (
        <div className="cellAction">
          <Link to={`/form-update/${encodeURIComponent(params.row.id)}`} style={{ textDecoration: "none" }}>
            <div className="updateButton">Editar</div>
          </Link>
          <DeleteIcon className="deleteIcon" onClick={() => handleDelete(params.row.id)} />

        </div>
      ),
    },
  ];
  
  /**
   * Formularios filtrados según el término de búsqueda.
   *
   * @type {Object[]}
   */
  const filteredFormularios = formularios.filter((formulario) =>
    formulario.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="datatable">
      <div className="datatableTitle">
        <h2 className="title-registro">Formularios</h2>
        <div className='button-container'>
          <div className="searchContainer">
            <input
              className="searchInput"
              label="Buscar"
              placeholder='Buscar'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <SearchIcon className="searchIcon" />
          </div>
          <div className='add-container'>
            <Link to="/form-creator" className="link">
              Crear formulario
            </Link>
          </div>
        </div>
      </div>
      <div className="data-grid-container">
        <DataGridComponent
          rows={filteredFormularios}
          columns={columns}
        />
      </div>
    </div>
  );
};

export default FormListScreen;