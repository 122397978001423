import "./list.scss"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import Datatable from "../../components/datatable/DatatableClientes"
import { useSidebarState } from "../../components/sidebar/SidebarStateContext";

const ListClientes = () => {
  const { collapsed } = useSidebarState();

  // Agregar una clase basada en el estado de colapso
  const containerClass = collapsed ? "listContainer collapsed" : "listContainer";

  return (
    <div data-testid="sidebar3" className="list">
      <Sidebar/>
      <div data-testid="navbar3" className={containerClass}>
        <Navbar/>
        <Datatable/>
      </div>
    </div>
  )
}

export default ListClientes