import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { tiendasColumns } from "../../datatablesource";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  onSnapshot,
  query,
  where,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import customLocaleText  from './LocaleText.js'

/**
 * Componente para mostrar una tabla de datos.
 */
const DatatableTiendas = () => {
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      let list = [];
      try {
        const serviciosSnapshot = await getDocs(collection(db, "servicios"));

        for (const servicioDoc of serviciosSnapshot.docs) {
          const tiendasSnapshot = await getDocs(collection(servicioDoc.ref, "tiendas"));

          tiendasSnapshot.forEach((tiendaDoc) => {
            const tiendaData = tiendaDoc.data();
            if (!tiendaData.borrado) {
              list.push({ id: tiendaDoc.id, ...tiendaData });
            }
          });
        }

        setData(list);
        console.log(list);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();

    // LISTEN (REALTIME)
    const unsub = onSnapshot(
      collection(db, "servicios"),
      async (serviciosSnapshot) => {
        let list = [];

        for (const servicioDoc of serviciosSnapshot.docs) {
          const tiendasSnapshot = await getDocs(collection(servicioDoc.ref, "tiendas"));

          tiendasSnapshot.forEach((tiendaDoc) => {
            const tiendaData = tiendaDoc.data();
            if (!tiendaData.borrado) {
              list.push({ id: tiendaDoc.id, ...tiendaData });
            }
          });
        }

        setData(list);
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsub();
    };
  }, []);

  /**
 * Maneja el evento de eliminación de un vigilante.
 * @param {string} id - ID del vigilante a eliminar.
 */
  const handleDelete = async (id) => {
    try {
      const confirmDelete = window.confirm("¿Estás seguro de que deseas eliminar esta tienda?");
      if (confirmDelete) {
        const serviciosSnapshot = await getDocs(collection(db, "servicios"));

        for (const servicioDoc of serviciosSnapshot.docs) {
          const tiendaRef = doc(servicioDoc.ref, "tiendas", id);
          await deleteDoc(tiendaRef, { borrado: true });
        }

        setData(data.filter((item) => item.id !== id));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const filteredData = data.filter((row) => {
    const rowValues = Object.values(row).join('').toLowerCase();
    return rowValues.includes(filter.toLowerCase());
  });


  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link to={`/tiendas/update/${encodeURIComponent(params.row.id)}`} style={{ textDecoration: "none" }}>
              <div className="updateButton">Actualizar</div>
            </Link>
            <div className="deleteButton" onClick={() => handleDelete(params.row.id)}>
              Borrar
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="datatable">
      <div className="datatableTitle">
        Registrar Tienda
        <Link to="/tiendas/newTienda" className="link">
          Añadir
        </Link>
        <div className="link">
          <input
            type="text"
            placeholder="Buscar"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
        </div>
      </div>
      <DataGrid
        className="datagrid"
        rows={filteredData}
        columns={tiendasColumns.concat(actionColumn)}
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection
        localeText={customLocaleText}
      />
    </div>
  );
};

export default DatatableTiendas;
