import React, { useEffect, useState, useContext } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import { AuthContext } from '../../context/AuthContext';
import { db } from '../../firebase';
import { collection, getDocs, where, query as firestoreQuery, doc } from 'firebase/firestore';
import { format } from 'date-fns';
import './chart.scss';

const BarChartWidget = () => {
  const { currentService } = useContext(AuthContext);
  const [dailyCounts, setDailyCounts] = useState(null);
  const [chartWidth, setChartWidth] = useState(1200); // Valor por defecto

  useEffect(() => {
    if (currentService) {
      const serviceDocRef = doc(db, 'servicios', currentService.id);
      const documentsRef = collection(serviceDocRef, 'documents');

      const startDate = new Date();
      startDate.setDate(startDate.getDate() - 6);
      const endDate = new Date();

      const query = firestoreQuery(
        documentsRef,
        where('fecha', '>=', startDate),
        where('fecha', '<=', endDate)
      );

      getDocs(query)
        .then((querySnapshot) => {
          const counts = {};

          querySnapshot.forEach((doc) => {
            const documentData = doc.data();
            const documentType = documentData.documentTypeId;
            const documentDate = documentData.fecha.toDate();
            const dayOfWeek = format(documentDate, 'EEEE');

            if (!counts[dayOfWeek]) {
              counts[dayOfWeek] = {
                ims: 0,
                intervencion: 0,
                denuncia: 0,
              };
            }

            counts[dayOfWeek][documentType]++;
          });

          const allDaysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

          allDaysOfWeek.forEach((day) => {
            if (!counts[day]) {
              counts[day] = {
                ims: 0,
                intervencion: 0,
                denuncia: 0,
              };
            }
          });

          const sortedDaysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

          const data = sortedDaysOfWeek.map((day) => ({
            day,
            ims: counts[day].ims,
            intervencion: counts[day].intervencion,
            denuncia: counts[day].denuncia,
          }));

          // Calculate totals
          const totalIms = data.reduce((acc, curr) => acc + curr.ims, 0);
          const totalIntervencion = data.reduce((acc, curr) => acc + curr.intervencion, 0);
          const totalDenuncia = data.reduce((acc, curr) => acc + curr.denuncia, 0);

          setDailyCounts({
            data,
            totalIms,
            totalIntervencion,
            totalDenuncia
          });
        })
        .catch((error) => {
          console.error('Error al obtener documentos:', error);
        });
    }
  }, [currentService]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1100) {
        setChartWidth(1200 * 0.25); // Reducir el ancho al 25%
      } else if (window.innerWidth < 1450) {
        setChartWidth(1200 * 0.7); // Reducir el ancho en un 30%
      } else {
        setChartWidth(1200); // Ancho original
      }
    };

    // Escuchar el evento resize
    window.addEventListener('resize', handleResize);

    // Llamar a la función una vez al montar el componente para establecer el ancho inicial
    handleResize();

    // Limpiar el evento al desmontar el componente
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const renderLegend = (props) => {
    const { payload } = props;
    return (
      <div className="custom-legend">
        {payload.map((entry, index) => (
          <div key={`item-${index}`} className="custom-legend-item">
            <div className="custom-legend-item-title" style={{ color: entry.color }}>
              {entry.value}
            </div>
            <div style={{ color: entry.color }}>
              {entry.payload[entry.dataKey]}
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="chart-container">
      <div className="chart-content">
        {dailyCounts ? (
          <div>
            <h6 className="chart-title">DATOS SEMANALES</h6>
            <BarChart width={chartWidth} height={400} data={dailyCounts.data}> {/* Ajusta el width según lo que necesites */}
              <XAxis dataKey="day" />
              <YAxis />
              <Tooltip />

              <Legend content={renderLegend} payload={[
                { value: 'denuncia', color: 'rgba(70, 120, 195, 1)', dataKey: 'denuncia', payload: { denuncia: dailyCounts.totalDenuncia } },
                { value: 'intervencion', color: 'rgba(26, 41, 66, 1)', dataKey: 'intervencion', payload: { intervencion: dailyCounts.totalIntervencion } },
                { value: 'ims', color: 'rgba(122, 164, 192, 1)', dataKey: 'ims', payload: { ims: dailyCounts.totalIms } },
              ]} />
              <Bar dataKey="denuncia" fill="rgba(70, 120, 195, 1)" barSize={35} isAnimationActive={false} />
              <Bar dataKey="intervencion" fill="rgba(26, 41, 66, 1)" barSize={35} isAnimationActive={false} />
              <Bar dataKey="ims" fill="rgba(122, 164, 192, 1)" barSize={35} isAnimationActive={false} />
            </BarChart>
          </div>
        ) : (
          <p>Cargando datos...</p>
        )}
      </div>
    </div>
  );
};

export default BarChartWidget;
