import React, { useState, useEffect, useContext } from 'react';
import { db } from '../../firebase';
import { AuthContext } from "../../context/AuthContext";
import { collection, addDoc, getDocs, where, query } from "firebase/firestore";
import { TextField, Select, MenuItem, Button, Typography, InputLabel, FormControl, Checkbox, FormControlLabel } from '@mui/material'; // Importamos los componentes de Material-UI
import './styles/formCreator.scss';
import FormPreview from './FormPreview';
import { v4 as uuidv4 } from 'uuid';
import IconPicker from './formUtils/IconPicker';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Popover from '@mui/material/Popover';
import DeleteIcon from '@mui/icons-material/Delete'; // Importa el ícono de papelera


const FormCreator = () => {
  const { currentUser } = useContext(AuthContext);
  const [form, setForm] = useState({
    icon: '',
    export: '',
    name: '',
    cliente: [],
    inputs: {}
  });
  const [servicio, setServicio] = useState(null);
  const [errors, setErrors] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [selectedInput, setSelectedInput] = useState(null);
  const [selectedInputType, setSelectedInputType] = useState(null); // Estado para almacenar el tipo seleccionado
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Estado para controlar si el dropdown está abierto
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState('all');
  const [anchorEl, setAnchorEl] = useState(null);

  /**
   * Fetches the `servicio` data for the currently logged-in user.
   * @async
   * @function fetchServicio
   * @returns {void}
   */
  const fetchServicio = async () => {
    try {
      const serviciosRef = collection(db, 'servicios');
      const q = query(serviciosRef, where('admins', 'array-contains', currentUser.email));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const primerServicio = querySnapshot.docs[0];
        setServicio({ id: primerServicio.id, ...primerServicio.data() });
      }
    } catch (error) {
      console.error('Error al obtener el servicio:', error);
    }
  };

  const fetchClients = async () => {
    try {
      const clientsRef = collection(db, 'servicios', servicio.id, 'clientes');
      const clientDocs = await getDocs(clientsRef);
      const clientsList = clientDocs.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      console.log(clientsList)
      setClients(clientsList);
    } catch (error) {
      console.error('Error al obtener los clientes:', error);
    }
  };

  useEffect(() => {
    if (servicio) {
      fetchClients();
    }
  }, [servicio]);

  useEffect(() => {
    if (currentUser) {
      fetchServicio();
    }
  }, [currentUser]);

  useEffect(() => {
    if (selectedInputType) {
      handleAddInput();
      setIsDropdownOpen(false);  // Cierra el dropdown
    }
  }, [selectedInputType]);

  /**
 * Validates the form inputs.
 * @function isValid
 * @returns {boolean} True if the form is valid, false otherwise.
 */
  const isValid = () => {
    let errors = {};

    if (!form.name.trim()) errors.name = 'El nombre del formulario es obligatorio.';
    if (!form.export.trim()) errors.export = 'El tipo de exportación es obligatorio.';

    // Validación de inputs
    for (let input in form.inputs) {
      if (!form.inputs[input].title.trim()) errors[`${input}_title`] = 'El nombre del campo es obligatorio.';
      if (!form.inputs[input].name.trim()) errors[`${input}_name`] = 'El nombre es obligatorio.';
      if ((form.inputs[input].type === 'DROPBOX' || form.inputs[input].type === 'MULTI_DROPBOX') &&
        (!form.inputs[input].dropbox || form.inputs[input].dropbox.length === 0)) {
        errors[`${input}_options`] = 'Debes agregar al menos una opción.';
      }
    }

    setErrors(errors);
    return Object.keys(errors).length === 0; // Devuelve true si no hay errores
  };


  /**
   * Opens the modal for errors.
   * @function handleOpenModal
   * @returns {void}
   */
  const handleOpenModal = () => {
    setOpenModal(true);
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleOpenSuccessDialog = () => {
    setOpenSuccessDialog(true);
  };

  const handleInputClick = (id) => {   // Cambio de 'title' a 'id' para ser más preciso
    setSelectedInput(id);
  };

  const handleAddInput = () => {
    const newInputId = uuidv4(); // Genera un ID único
    const newOrder = Object.keys(form.inputs).length;
    const newTitle = `Input ${Object.keys(form.inputs).length + 1}`;
    setForm({
      ...form,
      inputs: {
        ...form.inputs,
        [newInputId]: {
          title: newTitle,
          name: newTitle, // Asignando el mismo valor al name
          id: newInputId,
          order: newOrder,
          type: selectedInputType // Usa el tipo seleccionado
        }
      }
    });
    setSelectedInputType(null); // Reset selected input type after handling
  };



  const handleTitleChange = (id, newTitle) => {
    const updatedInputs = { ...form.inputs };
    updatedInputs[id].title = newTitle;
    updatedInputs[id].name = newTitle;  // Asignamos el mismo valor al name
    setForm({
      ...form,
      inputs: updatedInputs
    });
  };


  const handleInputUpdate = (title, updatedInput) => {
    // Actualiza solo el input específico manteniendo el resto inalterado
    setForm((prevForm) => ({
      ...prevForm,
      inputs: {
        ...prevForm.inputs,
        [title]: {
          ...prevForm.inputs[title],
          ...updatedInput,
        },
      },
    }));
  };

  /**
   * Handles form submission, creates a new form in the database.
   * @async
   * @function handleFormSubmit
   * @param {Event} e - The event object.
   * @returns {void}
   */
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!isValid()) {
      handleOpenModal();
      return;
    }

    if (!servicio) {
      console.error('No se encontró un servicio para el usuario actual.');
      return;
    }

    const inputsWithoutId = Object.keys(form.inputs).reduce((acc, key) => {
      const { id, ...rest } = form.inputs[key];
      acc[rest.title] = rest;  // Usa el título como clave
      return acc;
    }, {});

    try {
      const formsRef = collection(db, 'servicios', servicio.id, 'forms');
      await addDoc(formsRef, { ...form, inputs: inputsWithoutId, name: form.name });
      console.log('Formulario creado correctamente:', form);
      handleOpenSuccessDialog();
    } catch (error) {
      console.error('Error al crear el formulario:', error);
    }
  };

  const handleAddOption = (title) => {
    const updatedInputs = { ...form.inputs };
    updatedInputs[title].dropbox = updatedInputs[title].dropbox ? [...updatedInputs[title].dropbox, ''] : [''];
    setForm({ ...form, inputs: updatedInputs });
  };

  const handleRemoveOption = (title, optionIndex) => {
    const updatedInputs = { ...form.inputs };
    updatedInputs[title].dropbox.splice(optionIndex, 1);
    setForm({ ...form, inputs: updatedInputs });
  };

  const handleOptionChange = (title, optionIndex, value) => {
    const updatedInputs = { ...form.inputs };
    updatedInputs[title].dropbox[optionIndex] = value;
    setForm({ ...form, inputs: updatedInputs });
  };

  const handleRemoveInput = (title) => {
    const updatedInputs = { ...form.inputs };
    delete updatedInputs[title];
    setForm({
      ...form,
      inputs: updatedInputs
    });
  };

  const handleSizeChange = (id, isChecked, size) => {
    const updatedInputs = { ...form.inputs };
    if (isChecked) {
      updatedInputs[id].size = size;
    } else {
      delete updatedInputs[id].size; // Remueve la propiedad si el checkbox no está seleccionado
    }
    setForm({
      ...form,
      inputs: updatedInputs
    });
  };


  const handleSelectInputOption = (type) => {
    setSelectedInputType(type);
  };

  /**
 * Gets a filtered version of the inputs, excluding the 'order' property.
 * @function getFilteredInputs
 * @param {Object} inputs - The form inputs object.
 * @returns {Object} The filtered inputs.
 */
  const getFilteredInputs = (inputs) => {
    return Object.fromEntries(
      Object.entries(inputs).map(([title, input]) => {
        const { order, ...filteredInput } = input;
        return [title, filteredInput];
      })
    );
  };

  return (
    <div className='formCreatorContainer'>
      <Typography className='title-formulario' variant="h3" sx={{ marginBottom: 3 }}>Crear Formulario </Typography>
      <div className='sections'>
        <div className="left-section">
          <form onSubmit={handleFormSubmit}>
            <div className="form-name-row">
              <TextField
                label="Nombre del Formulario"
                id="name"
                name="name"
                value={form.name || ''}
                onChange={handleInputChange}
                sx={{ marginTop: 1 }}
              />
              {errors.name && <Typography color="error">{errors.name}</Typography>}
            </div>
            <div className="input-row">
              <IconPicker
                sx={{ width: '25%', marginRight: 2, marginTop: 1, background: 'rgba(14, 119, 189, 1)' }}
                selectedIcon={form.icon}
                onSelect={(iconURL) => setForm(prev => ({ ...prev, icon: iconURL }))}
              />
              <FormControl sx={{ width: '25%', marginRight: 2, marginTop: 1 }}>
                <InputLabel htmlFor="export" className='input-color'>Export</InputLabel>
                <Select
                  labelId="export-label"
                  id="export"
                  name="export"
                  className='input-color'
                  value={form.export}
                  onChange={handleInputChange}
                >
                  <MenuItem value="">
                    <em>Seleccione...</em>
                  </MenuItem>
                  <MenuItem value="csv">CSV</MenuItem>
                  <MenuItem value="pdf">PDF</MenuItem>
                  <MenuItem value="foto">Foto</MenuItem>
                </Select>
              </FormControl>
              {errors.export && <Typography color="error">{errors.export}</Typography>}

              <FormControl sx={{ width: '25%', marginRight: 2, marginTop: 1, background: 'rgba(14, 119, 189, 1)' }}>
                <InputLabel htmlFor="client-selection" className='input-color'>Cliente</InputLabel>
                <Select
                  value={selectedClient}
                  className='input-color'
                  onChange={(e) => {
                    setSelectedClient(e.target.value);
                    if (e.target.value === 'all') {
                      setForm(prev => ({ ...prev, cliente: clients.map(client => client.id) }));
                    } else {
                      setForm(prev => ({ ...prev, cliente: [e.target.value] }));
                    }
                  }}
                >
                  <MenuItem value="all">Todos</MenuItem>
                  {clients.map(client => (
                    <MenuItem key={client.n} value={client.id}>{client.nombre}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Button
                type="button"
                onClick={(event) => {
                  setIsDropdownOpen(!isDropdownOpen);
                  setAnchorEl(event.currentTarget);
                }}
                className="action-button"
                sx={{ width: '25%', height: 50, marginRight: 1, marginTop: 1.5 }}
              >
                Añadir campo
              </Button>

            </div>
            <Popover
              open={isDropdownOpen}
              anchorEl={anchorEl}
              onClose={() => {
                setIsDropdownOpen(false);
                setAnchorEl(null);
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <div className="input-options" style={{ display: 'flex', flexDirection: 'column' }}>
                <Button style={{
                  WebkitJustifyContent: 'flex-start',
                  justifyContent: 'flex-start',
                  color: 'black'
                }} onClick={() => handleSelectInputOption('TEXT')}>Texto</Button>
                <Button style={{
                  WebkitJustifyContent: 'flex-start',
                  justifyContent: 'flex-start',
                  color: 'black'
                }} onClick={() => handleSelectInputOption('EMAIL')}>Correo electrónico</Button>
                <Button style={{
                  WebkitJustifyContent: 'flex-start',
                  justifyContent: 'flex-start',
                  color: 'black'
                }} onClick={() => handleSelectInputOption('DATE')}>Fecha</Button>
                <Button style={{
                  WebkitJustifyContent: 'flex-start',
                  justifyContent: 'flex-start',
                  color: 'black'
                }} onClick={() => handleSelectInputOption('URL')}>URL</Button>
                <Button style={{
                  WebkitJustifyContent: 'flex-start',
                  justifyContent: 'flex-start',
                  color: 'black'
                }} onClick={() => handleSelectInputOption('DATE_TIME')}>Fecha y hora</Button>
                <Button style={{
                  WebkitJustifyContent: 'flex-start',
                  justifyContent: 'flex-start',
                  color: 'black'
                }} onClick={() => handleSelectInputOption('NUMBER')}>Número</Button>
                <Button style={{
                  WebkitJustifyContent: 'flex-start',
                  justifyContent: 'flex-start',
                  color: 'black'
                }} onClick={() => handleSelectInputOption('UPLOAD_FILE')}>Adjuntar archivos</Button>
                <Button style={{
                  WebkitJustifyContent: 'flex-start',
                  justifyContent: 'flex-start',
                  color: 'black'
                }} onClick={() => handleSelectInputOption('UPLOAD')}>Foto</Button>
                <Button style={{
                  WebkitJustifyContent: 'flex-start',
                  justifyContent: 'flex-start',
                  color: 'black'
                }} onClick={() => handleSelectInputOption('DROPBOX')}>Opción (dropbox)</Button>
                <Button style={{
                  WebkitJustifyContent: 'flex-start',
                  justifyContent: 'flex-start',
                  color: 'black'
                }} onClick={() => handleSelectInputOption('MULTI_DROPBOX')}>Opciones múltiples</Button>
              </div>
            </Popover>

            {Object.entries(form.inputs).map(([id, input], index) => (
              <div key={id}
                style={{
                  border: selectedInput === id ? '2px solid rgba(14, 119, 189, 1)' : 'none',  // Aplicar borde si es el input seleccionado
                  padding: selectedInput === id ? '10px' : '0',  // Puede que desees agregar un padding si tiene borde para no "apretar" el contenido
                  margin: selectedInput === id ? '10px 0' : '0'
                }}
                onClick={() => handleInputClick(id)}  // Agregar la función click al div para que cuando se haga clic, se seleccione
              >
                <Typography variant="h6" sx={{ marginBottom: 2, marginTop: 3 }}>
                <h3 className="campo-title">CAMPO {index + 1}</h3>
                  <div className='form-input-row'>
                    <TextField
                      label="Nombre del campo"
                      type="text"
                      className='input-name'
                      value={input.title || ''}
                      onChange={(e) => handleTitleChange(id, e.target.value)}
                    />
                    <FormControl className='input-type' sx={{ marginLeft: 2 }} >
                      <InputLabel htmlFor="type">Tipo</InputLabel>
                      <Select
                        label="Tipo"
                        id={`type${id}`}
                        value={input.type}
                        onChange={(e) => handleInputUpdate(id, { type: e.target.value })}
                      >
                        <MenuItem value="TEXT">Texto</MenuItem>
                        <MenuItem value="EMAIL">Correo electrónico</MenuItem>
                        <MenuItem value="DATE">Fecha</MenuItem>
                        <MenuItem value="URL">URL</MenuItem>
                        <MenuItem value="DATE_TIME">Fecha y hora</MenuItem>
                        <MenuItem value="NUMBER">Número</MenuItem>
                        <MenuItem value="UPLOAD_FILE">Adjuntar archivos</MenuItem>
                        <MenuItem value="UPLOAD">Foto</MenuItem>
                        <MenuItem value="DROPBOX">Opción (dropbox)</MenuItem>
                        <MenuItem value="MULTI_DROPBOX">Opciones múltiples</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </Typography>


                {input.type === 'TEXT' && (
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={input.size === "LARGE"}
                          onChange={(e) => handleSizeChange(id, e.target.checked, 'LARGE')}
                          name="sizeLargeCheckbox"
                        />
                      }
                      label="Tamaño grande"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={input.size === "SHORT"}
                          onChange={(e) => handleSizeChange(id, e.target.checked, 'SHORT')}
                          name="sizeShortCheckbox"
                        />
                      }
                      label="Tamaño corto"
                    />
                  </div>
                )}


                {input.type === 'DROPBOX' && (
                  <div>
                    <Typography>Opciones:</Typography>
                    <ul>
                      {input.dropbox &&
                        input.dropbox.map((option, optionIndex) => (
                          <li key={optionIndex}>
                            <TextField
                              type="text"
                              value={option || ''}
                              onChange={(e) => handleOptionChange(id, optionIndex, e.target.value)}
                              className="input-field"
                            />
                            <Button type="button" onClick={() => handleRemoveOption(id, optionIndex)}>
                              Eliminar
                            </Button>
                          </li>
                        ))}
                    </ul>
                    <Button type="button" onClick={() => handleAddOption(id)}>
                      Agregar Opción
                    </Button>
                  </div>
                )}
                {input.type === 'MULTI_DROPBOX' && (
                  <div>
                    <Typography>Opciones:</Typography>
                    <ul>
                      {input.dropbox &&
                        input.dropbox.map((option, optionIndex) => (
                          <li key={optionIndex}>
                            <TextField
                              type="text"
                              value={option || ''}
                              onChange={(e) => handleOptionChange(id, optionIndex, e.target.value)}
                              className="input-field"
                            />
                            <Button type="button" onClick={() => handleRemoveOption(id, optionIndex)}>
                              Eliminar
                            </Button>
                          </li>
                        ))}
                    </ul>
                    <Button type="button" onClick={() => handleAddOption(id)}>
                      Agregar Opción
                    </Button>
                  </div>
                )}
                {errors[`${id}_title`] && <Typography color="error">{errors[`${id}_title`]}</Typography>}
                {errors[`${id}_name`] && <Typography color="error">{errors[`${id}_name`]}</Typography>}
                {errors[`${id}_options`] && <Typography color="error">{errors[`${id}_options`]}</Typography>}
                <Button
                  type="button"
                  className="remove-input-button"
                  onClick={() => handleRemoveInput(id)}
                >
                  <DeleteIcon color="inherit" />

                  Eliminar
                </Button>
              </div>
            ))}

            <Button type="submit" className="action-button" sx={{ marginTop: 1, height: 50, float: 'right', marginRight: 10 }}>Crear Formulario</Button>
          </form>
        </div>
        <div className="right-section">
          <FormPreview formTitle={form.name} formInputs={getFilteredInputs(form.inputs)} onInputClick={handleInputClick} />

        </div>
      </div>
      <Dialog
        open={openSuccessDialog}
        onClose={() => setOpenSuccessDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Formulario creado"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            El formulario se ha creado exitosamente.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenSuccessDialog(false)} color="primary">
            Entendido
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FormCreator;
