import { addNotification } from './firebaseNotifications';

const appId = process.env.REACT_APP_APP_ID;
const appToken = process.env.REACT_APP_APP_TOKEN;
const apiUrl = 'https://app.nativenotify.com/api/notification';

async function sendNotification(title, body, servicioId) {
  const currentDate = new Date().toLocaleString('en-US', { timeZone: 'America/New_York' });

  const requestBody = {
    appId,
    appToken,
    title,
    body,
    dateSent: currentDate,
  };

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(requestBody)
  };

  try {
    const response = await fetch(apiUrl, requestOptions);

    if (!response.ok) {
      throw new Error('Error al enviar la notificación');
    }

    const responseData = await response.text();

    if (responseData === 'Success!') {
      console.log('Notificación enviada con éxito');
      addNotification(title, body, 'all', currentDate, servicioId);
    } else {
      const data = JSON.parse(responseData);
      console.log('Notificación enviada:', data);
      addNotification(title, body, 'all', currentDate, servicioId);
    }
  } catch (error) {
    console.error('Error al enviar la notificación:', error);
  }
}

export { sendNotification };
