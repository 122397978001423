import "./new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState, useContext } from "react";
import { storage } from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { uploadLogToFirestore, uploadErrorLogToFirestore } from "../../utils/logUtils";
import { ImageUploader, ServiceDropdown, TiendaInput } from './utils/NewUtils';
import { fetchTiendas, fetchClientes, registerVigilante } from './utils/FirebaseUtils';
import { useSidebarState } from "../../components/sidebar/SidebarStateContext";

/**
 * Componente para crear una nueva entrada.
 *
 * @param {Object} inputs - Los campos de entrada para la nueva entrada.
 * @param {string} title - El título del formulario.
 * @returns {JSX.Element} - Componente New.
 */
const New = ({ inputs, title }) => {
  const [file, setFile] = useState("");
  const [data, setData] = useState({ tiendas: [] });
  const [per, setPerc] = useState(null);
  const [clientes, setClientes] = useState([]); // Estado para almacenar la lista de clientes
  const [selectedCliente, setSelectedCliente] = useState(""); // Estado para el cliente seleccionado
  const [tiendasCliente, setTiendasCliente] = useState([]); // Estado para las tiendas del cliente seleccionado
  const [tiendas, setTiendas] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState(tiendas.map(tienda => tienda.numTienda.toString()));

  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const { collapsed } = useSidebarState();

  // Agregar una clase basada en el estado de colapso
  const containerClass = collapsed ? "newContainer collapsed" : "newContainer";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const list = await fetchTiendas(currentUser.email);
        setTiendas(list);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, [currentUser.email]);

  useEffect(() => {
    const loadClientes = async () => {
      try {
        const listaClientes = await fetchClientes(currentUser.email);
        setClientes(listaClientes);
      } catch (err) {
        console.error("Error al cargar los clientes:", err);
      }
    };

    loadClientes();
  }, [currentUser.email]);


  useEffect(() => {
    const uploadFile = () => {
      const name = new Date().getTime() + file.name;

      const storageRef = ref(storage, file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          setPerc(progress);
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setData((prev) => ({ ...prev, img: downloadURL }));
          });
        }
      );
    };
    file && uploadFile();
  }, [file]);


  /**
 * Maneja el cambio de los campos de entrada.
 *
 * @param {Object} e - Evento del campo de entrada.
 */
  const handleInput = (e) => {
    const id = e.target.id;
    const value = e.target.value;

    setData({ ...data, [id]: value });
  };


  // Manejador de cambio de cliente seleccionado
  const handleClienteChange = (e) => {
    const clienteSeleccionado = e.target.value;
    setSelectedCliente(clienteSeleccionado);

    // Filtrar las tiendas asociadas al cliente seleccionado
    const tiendasFiltradas = tiendas.filter((tienda) => tienda.nombreCliente === clienteSeleccionado);
    setTiendasCliente(tiendasFiltradas);
  };


  /**
 * Maneja el cambio de los campos de entrada para las tiendas.
 *
 * @param {number} index - Índice de la tienda.
 * @param {string} field - Campo de entrada.
 * @param {string} value - Valor del campo de entrada.
 */
  const handleInputTienda = (index, field, value) => {
    setData((prev) => {
      const newTiendas = [...prev.tiendas];
      newTiendas[index][field] = value;
      return { ...prev, tiendas: newTiendas };
    });
  };

  /**
 * Maneja la eliminación de una tienda.
 *
 * @param {number} index - Índice de la tienda a eliminar.
 */
  const handleDeleteTienda = (index) => {
    setData((prev) => ({
      ...prev,
      tiendas: prev.tiendas.filter((_, i) => i !== index),
    }));
  };

  const handleAllServicesSelection = (isChecked) => {
    if (isChecked) {
      setSelectedOptions(["allServices", ...tiendas.map(tienda => tienda.numTienda.toString())]);
      setData((prev) => ({
        ...prev,
        tiendas: tiendas.map(tienda => ({ numero: tienda.numTienda }))
      }));
    } else {
      setSelectedOptions([]);
      setData((prev) => ({ ...prev, tiendas: [] }));
    }
  };

  const handleAllClientServicesSelection = (isChecked) => {
    if (isChecked) {
      setSelectedOptions(["allClientServices", ...tiendasCliente.map(tienda => tienda.numTienda.toString())]);
      setData((prev) => ({
        ...prev,
        tiendas: tiendasCliente.map(tienda => ({ numero: tienda.numTienda }))
      }));
    } else {
      setSelectedOptions([]);
      setData((prev) => ({ ...prev, tiendas: [] }));
    }
  };

  const handleIndividualServiceSelection = (value, isChecked) => {
    if (isChecked) {
      setSelectedOptions((prev) => [...prev, value]);
      // Convierte el valor en una cadena (string)
      const tiendaNumero = value.toString();
      console.log(tiendaNumero)
      setData((prev) => ({
        ...prev,
        tiendas: [...prev.tiendas, { numero: tiendaNumero }]
      }));
    } else {
      setSelectedOptions((prev) => prev.filter((option) => option !== value));
      // Convierte el valor en una cadena (string)
      const tiendaNumero = value.toString();
      setData((prev) => ({
        ...prev,
        tiendas: prev.tiendas.filter((tienda) => tienda.numero !== tiendaNumero)
      }));
    }
  };

  const handleCheckboxChange = (e) => {
    const value = e.target.value;
    const isChecked = e.target.checked;

    console.log(value)
    switch (value) {
      case "allServices":
        handleAllServicesSelection(isChecked);
        break;
      case "allClientServices":
        handleAllClientServicesSelection(isChecked);
        break;
      default:
        handleIndividualServiceSelection(value, isChecked);
        break;
    }
  };


  /**
 * Maneja el registro de la nueva entrada.
 *
 * @param {Object} e - Evento del formulario.
 */
  const handleAdd = async (e) => {
    e.preventDefault();

    // Check if there are duplicate stores
    const storeNumbers = data.tiendas.map((tienda) => tienda.numero);
    const uniqueStoreNumbers = new Set(storeNumbers);
    if (storeNumbers.length !== uniqueStoreNumbers.size) {
      window.alert("Registro fallido: Hay tiendas duplicadas");
      return;
    }

    // Check if there are no stores added
    if (storeNumbers.length === 0) {
      window.alert("Registro fallido: No se ha agregado ninguna tienda");
      return;
    }

    // Check if all selected store numbers are valid options
    const validStoreNumbers = tiendas.map((tienda) => tienda.numTienda);
    const selectedStoreNumbers = data.tiendas.map((tienda) => tienda.numero);
    const isValidStore = selectedStoreNumbers.every((number) =>
      validStoreNumbers.includes(number)
    );
    if (!isValidStore) {
      window.alert(
        "Registro fallido: El identificador de servicio seleccionado no es válido"
      );
      return;
    }

    // If there are no duplicate stores and all selected store numbers are valid, proceed to add the vigilante
    try {
      const result = await registerVigilante(data, currentUser.email);
      console.log(result)
      if (result && result.success) {
        uploadLogToFirestore("nuevo vigilante añadido con id: " + result.uid);
        navigate(-1);
      } else {
        throw new Error(result.error);
      }
    } catch (err) {
      console.log(err);
      uploadErrorLogToFirestore("error registrando vigilante: " + err.message);
      if (err.message.includes("password")) {
        window.alert(
          "Registro fallido: La contraseña debe tener al menos 6 caracteres"
        );
      } else if (err.message.includes("email-already-in-use")) {
        window.alert("Registro fallido: El vigilante ya ha sido registrado");
      } else {
        window.alert("Registro fallido: " + err.message);
      }
    }
  };

  return (
    <div className="new">
      <Sidebar />
      <div className={containerClass}>
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          <ImageUploader file={file} setFile={setFile} />
          <div className="right">
            <form onSubmit={handleAdd}>
              <div className="formInput">
                <div className="inputPairs">
                  <select className="cliente-select" value={selectedCliente} onChange={handleClienteChange}>
                    <option value="">Seleccionar cliente</option>
                    {clientes.map((cliente) => (
                      <option key={cliente} value={cliente}>
                        {cliente}
                      </option>
                    ))}
                  </select>
                  <ServiceDropdown
                    tiendas={tiendas}
                    selectedOptions={selectedOptions}
                    handleCheckboxChange={handleCheckboxChange}
                    selectedCliente={selectedCliente} // Pasa el cliente seleccionado como prop
                  />
                  <TiendaInput
                    tiendas={tiendas}
                    data={data}
                    handleInputTienda={handleInputTienda}
                    handleDeleteTienda={handleDeleteTienda}
                  />
                </div>
              </div>
              <div className="formInput">
                <label>Nombre:</label>
                <input
                  id="nombre"
                  type="text"
                  placeholder="Nombre"
                  onChange={handleInput}
                  required
                />
              </div>
              <div className="formInput">
                <label>Apellidos:</label>
                <input
                  id="apellidos"
                  type="text"
                  placeholder="Apellidos"
                  onChange={handleInput}
                  required
                />
              </div>
              {/* Agrupa los campos de entrada en pares, excepto nombre y apellidos */}
              <div className="inputPairs">
                {inputs.map((input, index) => (
                  <div className="formInput" key={input.id}>
                    <label>{input.label}</label>
                    <input
                      id={input.id}
                      type={input.type}
                      placeholder={input.placeholder}
                      onChange={handleInput}
                      required
                    />
                  </div>
                ))}
              </div>
              <div className="button-container">
                <button disabled={per !== null && per < 100} type="submit">
                  Registrar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default New;