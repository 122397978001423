import React from 'react';
import './UserDropdown.scss';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Link } from "react-router-dom";
import { useContext } from "react";
import { LogoutContext } from "../../context/LogoutContext";

const UserDropdown = ({ onClose }) => {
    const handleLogout = useContext(LogoutContext);

    const handleLogoutClick = () => {
        console.log('Logout button clicked');
        handleLogout(); // Llamada a la función handleLogout del contexto
    };
    return (
        <div className="user-dropdown">
            <ul>
                <li onClick={onClose}>
                    <PersonIcon className="menu-icon" />
                    <Link to="/">Mi perfil</Link>
                </li>
                <li onClick={onClose}>
                    <SettingsIcon className="menu-icon" />
                    <Link to="/">Configuración</Link>
                </li>
                <Link to="/login">
                    <li>
                        <ExitToAppIcon className="menu-icon" onClick={handleLogoutClick} />
                        Logout
                    </li>
                </Link>
            </ul>
        </div>
    );
};

export default UserDropdown;
